import { Modal, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneTextField from "../common/PhoneTextField";
import { UserContext } from "../data/contexts/UserContext";
import { isNotEmpty, userToUserUpdate } from "../data/dataConvertors";
import { ProcedureStatus, User } from "../data/generated/graphql";
import GdAlert from "../utils/GdAlert";
import GdButton from "../utils/GdButton";
import { Lvl, log } from "../utils/log";
import SignatureIframe from "../common/SignatureIframe";
import { YousignEventData } from "../utils/yousignIframeSDK";

interface UserCharterModalProps {
  open: boolean;
  onClose: () => void;
  onUserSigned: (userJustSigned?: boolean) => void;
}

const emptyUser: User = {
  id: "",
};

const UserCharterModal: FC<UserCharterModalProps> = ({ open, onClose, onUserSigned }) => {
  const { userInfo, getUser, updateUser, updateError, checkCharter, checkCharterError } = useContext(UserContext);
  const [newUser, setNewUser] = useState(userToUserUpdate(userInfo || emptyUser));
  const [isUpdating, setIsUpdating] = useState(false);
  const [signAlertOpen, setSignAlertOpen] = useState(false);
  const [refusedAlertOpen, setRefusedAlertOpen] = useState(false);
  const [showSignatureUrl, setShowSignatureUrl] = useState("");
  const { t } = useTranslation(["user", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (updateError || checkCharterError) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
      setIsUpdating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError, checkCharterError]);

  const close = (): void => {
    setNewUser(userToUserUpdate(userInfo || emptyUser));
    onClose();
  };

  const userRefused = (): void => {
    setRefusedAlertOpen(true);
    // Reset process
    setShowSignatureUrl("");
  };

  const userSigned = (): void => {
    getUser();
    onUserSigned(true);
    close();
  };

  useEffect(() => {
    setNewUser(userToUserUpdate(userInfo || emptyUser));
  }, [userInfo]);

  const update = async (): Promise<User | null> => {
    setIsUpdating(true);
    const res = await updateUser(newUser);
    if (res) {
      const checkRes = await checkCharter(true);
      if (checkRes && checkRes.userCharter?.status === ProcedureStatus.Created) {
        // Charter is ready to be signed
        const signUrl = checkRes.userCharter?.details?.members ? checkRes.userCharter?.details?.members[0].url : "";
        setShowSignatureUrl(signUrl || "");
      }
    }
    setIsUpdating(false);
    return res;
  };
  const fnChange =
    (userProp: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void =>
      setNewUser({ ...newUser, [userProp]: typeof e === "string" ? e : e.target.value });
  const isUserDetailsComplete =
    isNotEmpty(newUser.firstName) &&
    isNotEmpty(newUser.lastName) &&
    isNotEmpty(newUser.email) &&
    isNotEmpty(newUser.phone) &&
    isNotEmpty(newUser.role);

  const onYousignError = (data: YousignEventData): void => {
    log("Yousign sent an error", Lvl.ERROR, data);
    enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
  };

  const signature = (
    <SignatureIframe
      signatureUrl={showSignatureUrl}
      onDeclined={userRefused}
      onSuccess={userSigned}
      onError={onYousignError}
    />
  );

  const detailsForm = (
    <div className="charter-details-form">
      <Typography variant="h6" className="margin-bottom">
        {t("charterModalTitle")}
      </Typography>
      <Typography variant="subtitle1" className="margin-bottom">
        {t("charterModalSubtitle")}
      </Typography>
      <TextField
        label={t("lastName")}
        variant="outlined"
        value={newUser.lastName}
        onChange={fnChange("lastName")}
        fullWidth
      />
      <TextField
        label={t("firstName")}
        variant="outlined"
        value={newUser.firstName}
        onChange={fnChange("firstName")}
        fullWidth
      />
      <TextField label={t("role")} variant="outlined" value={newUser.role} onChange={fnChange("role")} fullWidth />
      <TextField label={t("email")} variant="outlined" value={newUser.email} onChange={fnChange("email")} fullWidth />
      <PhoneTextField
        label={t("phone")}
        value={newUser.phone || ""}
        onChange={fnChange("phone")}
        style={{ width: "100%" }}
      />
      <div className="row space-evenly">
        <GdButton color="secondary" label={t("global:cancel")} onClick={close} />
        <GdButton
          label={t("charterDetailsValidate")}
          onClick={update}
          isLoading={isUpdating}
          disabled={!isUserDetailsComplete}
        />
      </div>
    </div>
  );

  return (
    <>
      <Modal open={open} onClose={close} className="project-modal-root">
        <Paper className="project-modal-paper charter-modal">
          {showSignatureUrl.length > 0 ? signature : detailsForm}
        </Paper>
      </Modal>
      <GdAlert
        open={signAlertOpen}
        onClose={() => setSignAlertOpen(false)}
        title={t("signAlertTitle")}
        body={t("signAlertBody")}
      />
      <GdAlert
        open={refusedAlertOpen}
        onClose={() => setRefusedAlertOpen(false)}
        title={t("refusedAlertTitle")}
        body={t("refusedAlertBody")}
      />
    </>
  );
};

export default UserCharterModal;
