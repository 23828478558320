import { Delete, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AssistantContext } from "../data/contexts/AssistantContext";
import { dateConvertToString } from "../data/dataConvertors";
import { Conversation } from "../data/generated/graphql";
import "../project/components/AiAssistant.css";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import GdButton from "../utils/GdButton";
import GreenyPicto from "../utils/pictos/greeny-picto";
import AssistantQA from "./AssistantQA";
import Pagination from "./Pagination";

interface AiHistoryItemProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  conversation: Conversation;
}

const AiHistoryItem: FC<AiHistoryItemProps> = ({ onOpen, open, onClose, conversation }) => {
  const { t } = useTranslation("aiAssistant");
  const { setOpenGreeny, setCurrentConversation, deleteConversation } = useContext(AssistantContext);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const openGreeny = (): void => {
    setOpenGreeny(true);
    setCurrentConversation(conversation);
    onClose();
  };
  const tryDeleteConversation = async (): Promise<void> => {
    setDeleting(true);
    const result = await deleteConversation(conversation.id);
    if (!result) enqueueSnackbar(t("assistantHistory.errorWhileDeleting"), { variant: "error" });
    setDeleting(false);
  };
  const latestQuestion = conversation.qas?.[conversation.qas?.length - 1 || 0]?.question || "";
  const latestQuestionDate = (conversation.qas?.[conversation.qas?.length - 1 || 0]?.date || 0) * 1000;
  return (
    <Accordion
      expanded={open}
      onChange={(_, expanded) => {
        if (expanded) onOpen();
        else onClose();
      }}
      sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div className="flex1 row space-between">
          <Typography>
            {latestQuestion.length <= 50 ? latestQuestion : `${latestQuestion.substring(0, 50)}...`}{" "}
            <i>{`${t("assistantHistory.accordionDetails")}${dateConvertToString(
              new Date(latestQuestionDate),
              true,
            )}`}</i>
          </Typography>
          <div className="row">
            <Tooltip title={t("assistantHistory.openGreeny")} arrow placement="right" sx={{ zIndex: 42 }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openGreeny();
                }}>
                <GreenyPicto width={18} height={18} fill="#757575" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("assistantHistory.deleteConversation")} arrow placement="right" sx={{ zIndex: 42 }}>
              {deleting ? (
                <CircularProgress size={18} sx={{ margin: "0 16px" }} />
              ) : (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    tryDeleteConversation();
                  }}>
                  <Delete width={18} height={18} color="error" />
                </IconButton>
              )}
            </Tooltip>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="conversation" style={{ maxWidth: "unset" }}>
          {conversation.qas?.map((qa) => (
            <AssistantQA qa={qa} key={qa.date} id={conversation.id} />
          ))}
        </div>
        <GdButton label={t("assistantHistory.openGreeny")} onClick={openGreeny} />
      </AccordionDetails>
    </Accordion>
  );
};

const AiHistory: FC = () => {
  const { t } = useTranslation("aiAssistant");
  const [conversationOpen, setConversationOpen] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const {
    conversationPages,
    getConversationHistory,
    setOpenGreeny,
    setCurrentConversation,
    isAssistantActive,
    pageCount,
    latestReset,
    currentConversationHistoryPage,
    loading,
  } = useContext(AssistantContext);

  useEffect(() => {
    if (isAssistantActive) {
      (async () => {
        await getConversationHistory(0);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchTerm("");
    setConversationOpen("");
  }, [latestReset]);

  const getNewPage = async (newPage: number): Promise<void> => {
    await getConversationHistory(newPage);
  };

  const search = async (): Promise<void> => {
    await getConversationHistory(0, { searchTerm });
    setConversationOpen("");
  };

  const listMaxHeight = window.innerHeight - (64 + 2 * 24 + 2 * 32 + 56 + 32 + 16 + 24);

  return (
    <BaseCard>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}>
        <div className="row space-between big-margin-bottom" style={{ width: "100%" }}>
          <Typography variant="h6">{t("assistantHistory.title")}</Typography>
          <TextField
            label={t("search")}
            variant="outlined"
            sx={{ width: "320px" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === "enter") {
                search();
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton className="circle" onClick={() => search()} color="primary">
                  <Search />
                </IconButton>
              ),
            }}
          />
          <GdButton
            onClick={() => {
              setOpenGreeny(true);
              setCurrentConversation(undefined);
            }}
            label={t("newConversation")}
          />
        </div>
        {loading ? (
          <div style={{ width: "100%", height: `${listMaxHeight}px`, display: "flex", alignItems: "center" }}>
            <CenteredContent loading />
          </div>
        ) : isAssistantActive ? (
          typeof conversationPages[currentConversationHistoryPage] !== "undefined" ? (
            conversationPages[currentConversationHistoryPage].length > 0 ? (
              <div style={{ height: `${listMaxHeight}px`, overflowY: "auto" }}>
                {conversationPages[currentConversationHistoryPage].map((c) =>
                  typeof c.qas !== "undefined" ? (
                    <AiHistoryItem
                      conversation={c}
                      key={c.id}
                      open={conversationOpen === c.id}
                      onOpen={() => setConversationOpen(c.id)}
                      onClose={() => setConversationOpen("")}
                    />
                  ) : undefined,
                )}
              </div>
            ) : (
              <div style={{ margin: "auto", height: `${listMaxHeight}px`, display: "flex", alignItems: "center" }}>
                <Typography variant="h6">{t("searchNoResult")}</Typography>
              </div>
            )
          ) : (
            <div style={{ height: `${listMaxHeight}px` }}>
              <Typography className="text-center margin-top">{t("assistantHistory.noConversation")}</Typography>
              <Button
                variant="outlined"
                sx={{ width: "300px", margin: "auto" }}
                onClick={() => {
                  setOpenGreeny(true);
                  setCurrentConversation(undefined);
                }}>
                {t("assistantHistory.firstConversationButton")}
              </Button>
            </div>
          )
        ) : (
          <CenteredContent infoText={t("assistantHistory.noAuthorization")} />
        )}
        <Pagination pageCount={pageCount} currentPage={currentConversationHistoryPage} getNewPage={getNewPage} />
      </div>
    </BaseCard>
  );
};

export default AiHistory;
