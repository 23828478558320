import { Autocomplete, Card, SvgIconTypeMap, TextField, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  AcUnit,
  Air,
  AirlineSeatReclineNormal,
  Apartment,
  CarRepair,
  DirectionsBusFilled,
  HelpOutline,
  Lightbulb,
  LocalFireDepartment,
  Moving,
  Plumbing,
  Settings,
} from "@mui/icons-material";
import { Form } from "./formsTypes";
import Theme from "../../Theme";

import "./FormFamily.css";
import { Sector } from "../generated/graphql";
import { getActiveFormsId, getFormsForSector } from "./FormHelper";

export const FormFamilies = ["Froid", "Thermique", "Air comprimé", "Moteur", "Bâtiment", "Autres"];

const getIconComponent = (
  familyName: string,
): OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
} => {
  switch (familyName) {
    case "Froid":
      return AcUnit;
    case "Thermique":
      return LocalFireDepartment;
    case "Air comprimé":
      return Air;
    case "Moteur":
      return Settings;
    case "Bâtiment":
      return Apartment;
    case "Équipement":
      return Plumbing;
    case "Véhicule":
      return DirectionsBusFilled;
    case "Service":
      return CarRepair;
    case "Optimisation":
      return Moving;
    case "Chauffeur":
      return AirlineSeatReclineNormal;
    case "Éclairage":
      return Lightbulb;
    default:
      return HelpOutline;
  }
};

const getIcon = (familyName: string): ReactNode => {
  const Icon = getIconComponent(familyName);
  return <Icon style={{ fontSize: 56, marginBottom: 8 }} />;
};

interface TypeButtonProps {
  familyName: string;
  onClick: (family: string) => void;
}

const TypeButton: FC<TypeButtonProps> = ({ familyName, onClick }) => (
  <Card
    style={{
      color: Theme.palette.primary.main,
    }}
    elevation={3}
    className="forms-type-card"
    onClick={() => onClick(familyName)}>
    {getIcon(familyName)}
    <Typography variant="h5">{familyName}</Typography>
  </Card>
);

interface TypeButtonsProps {
  onSelect: (selected: string) => void;
  families: string[];
}

const TypeButtons: FC<TypeButtonsProps> = ({ onSelect, families }) => {
  const firstLine: ReactNode[] = [];
  for (let i = 0; i < 3; i += 1) {
    if (families[i]) firstLine.push(<TypeButton key={families[i]} familyName={families[i]} onClick={onSelect} />);
    else firstLine.push(<div key={`empty_${i}`} className="forms-type-empty" />);
  }
  const secondLine: ReactNode[] = [];
  for (let i = 3; i < 6; i += 1) {
    if (families[i]) secondLine.push(<TypeButton key={families[i]} familyName={families[i]} onClick={onSelect} />);
    else secondLine.push(<div key={`empty_${i}`} className="forms-type-empty" />);
  }
  return (
    <>
      <div className="forms-type-row" style={families.length > 3 ? { marginBottom: 32 } : undefined}>
        {firstLine}
      </div>
      {families.length > 3 ? <div className="forms-type-row">{secondLine}</div> : undefined}
    </>
  );
};

interface FormFamilyProps {
  sector: Sector;
  setFamily: (family: string) => void;
  setForm: (form?: Form) => void;
  zipCode: string;
}

const FormFamily: FC<FormFamilyProps> = ({ sector, setFamily, setForm, zipCode }) => {
  const { t } = useTranslation("project");
  const selectableForms = getFormsForSector(sector, zipCode).filter(
    (f) => getActiveFormsId(sector).indexOf(f.id) !== -1,
  );
  const families: string[] = [];
  selectableForms.forEach((f) => (families.indexOf(f.type) === -1 ? families.push(f.type) : undefined));
  return (
    <div className="family-selector">
      <Typography variant="h6" className="margin-bottom">
        {t("operationTypeChoice")}
      </Typography>
      <Typography style={{ marginBottom: 16 }}>{t("userKnowsForm")}</Typography>
      <Autocomplete
        className="margin-bottom"
        id="combo-box-demo"
        options={selectableForms}
        getOptionLabel={(option) => `${option.id} - ${option.name}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
        fullWidth
        onChange={(_, newValue) => {
          if (newValue) {
            const formId = newValue.id.split(" ")[0];
            setForm(selectableForms.find((f) => f.id === formId));
          }
        }}
      />
      <Typography className="margin-bottom">{t("userDoesntKnowForm")}</Typography>
      <div className="margin-bottom">
        <TypeButtons onSelect={setFamily} families={families} />
      </div>
      {sector === Sector.Res || sector === Sector.Bar ? (
        <Typography variant="subtitle2" style={{ textAlign: "center" }}>
          {t("incompleteSector")}
        </Typography>
      ) : undefined}
    </div>
  );
};

export default FormFamily;
