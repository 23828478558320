import { Card, Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface ValueCardProps {
  title: string;
  value: string;
  forDashboard?: boolean;
}

const ValueCard: FC<ValueCardProps> = ({ title, value, forDashboard }) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        padding: 32,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: forDashboard ? 260 : 220,
        height: forDashboard ? 200 : 190,
        whiteSpace: "pre-line",
        borderWidth: 3,
        borderRadius: 16,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        margin: forDashboard ? 0 : 16,
        textAlign: "center",
      }}
      color="primary"
      variant="outlined">
      {forDashboard ? (
        <>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {value}
          </Typography>
          <Typography variant="h5">{title}</Typography>
        </>
      ) : (
        <>
          <Typography variant="h6" className="margin-bottom">
            {title}
          </Typography>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {value}
          </Typography>
        </>
      )}
    </Card>
  );
};

ValueCard.defaultProps = { forDashboard: false };

export default ValueCard;
