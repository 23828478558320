/* eslint-disable @typescript-eslint/naming-convention */
// __typename is a GraphQL naming convention!
import {
  BonusDetails,
  BonusDetailsInput,
  Company,
  CompanyInput,
  DataField,
  DataFieldInput,
  Maybe,
  Operation,
  OperationInput,
  Project,
  ProjectDetails,
  ProjectDetailsInput,
  ProjectUpdate,
  Signer,
  SignerInput,
  User,
  UserUpdate,
} from "./generated/graphql";
import { ConsolidatedComputation } from "./types/ProjectTypes";

export const companyToCompanyInput = (company: Company): CompanyInput => {
  const { __typename, ...result } = company;
  return result;
};
export const userToUserUpdate = (user: User): UserUpdate => {
  const company = user.company ? companyToCompanyInput(user.company) : undefined;
  const { __typename, name, joined, userCharter, claims, assistantSubscription, ...result } = user;
  return { ...result, company };
};

export const emptySigner = { company: { siret: "" } };
export const signerToSignerInput = (signer?: Maybe<Signer> | User): SignerInput => {
  if (!signer) return emptySigner;
  const company = signer.company ? companyToCompanyInput(signer.company) : { siret: "" };
  const { __typename, id, userCharter, ...result } = signer as User;
  return { ...result, company };
};
export const detailsToDetailsInput = (details: ProjectDetails): ProjectDetailsInput => {
  const { __typename, ...result } = details;
  return result;
};
const dataFieldToDataFieldInput = (dataField: DataField): DataFieldInput => {
  const { __typename, ...result } = dataField;
  return result;
};
export const bonusDetailsToBonusDetailsInput = (bonusDetails: BonusDetails): BonusDetailsInput => {
  const { __typename, ...result } = bonusDetails;
  return result;
};

export const operationsToOperationsInput = (operations: Operation[]): OperationInput[] => {
  return operations.map((o) => {
    const { __typename, computation, ...result } = o;
    result.data = result.data ? result.data.map((d) => dataFieldToDataFieldInput(d as DataField)) : [];
    result.cdpData = result.cdpData?.map((cdp) => dataFieldToDataFieldInput(cdp as DataField));
    result.bonusDetails = result.bonusDetails?.map((bonusDetail) =>
      bonusDetailsToBonusDetailsInput(bonusDetail as BonusDetails),
    );
    return result;
  });
};

export const projectToProjectUpdate = (project: Project): ProjectUpdate => {
  const client = signerToSignerInput(project.client);
  const clientSigner = signerToSignerInput(project.clientSigner);
  const userSigner = project.userSigner ? signerToSignerInput(project.userSigner) : undefined;
  const details = project.details ? detailsToDetailsInput(project.details) : undefined;
  const operations = project.operations ? operationsToOperationsInput(project.operations as Operation[]) : undefined;
  const { __typename, totalComputation, ...result } = project;
  return { ...result, client, clientSigner, details, operations, userSigner };
};

export const dateConvertToString = (date?: Date | Maybe<string>, withTime?: boolean): string => {
  if (!date) return "?";
  const actualDate: Date = typeof date === "string" ? new Date(date) : date;
  return withTime
    ? `${actualDate.toLocaleDateString()} - ${actualDate.getHours()}:${actualDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    : actualDate.toLocaleDateString();
};

export const dataDateConvertToDate = (dataDate: string): Date | null => {
  if (dataDate.length !== 10) return null;
  return new Date(`${dataDate}T05:00:00Z`);
};

export const dateConvertToDataDate = (date: Date | null): string => {
  return date !== null && !Number.isNaN(date.getTime()) ? date.toISOString().substring(0, 10) : "";
};

export const isoDateToFrDate = (date: string): string => {
  return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`;
};

export const consolidateProjectsComputation = (projects: Project[]): ConsolidatedComputation => {
  const result: ConsolidatedComputation = {
    capacity: 0,
    valuation: 0,
    nbProjects: 0,
  };

  projects.forEach((p) => {
    const pCapacity = p.totalComputation ? parseInt(p.totalComputation.capacity, 10) : 0;
    const pValuation = p.totalComputation ? parseInt(p.totalComputation.valuation, 10) : 0;
    result.capacity += Number.isNaN(pCapacity) ? 0 : pCapacity;
    result.valuation += Number.isNaN(pValuation) ? 0 : pValuation;
    result.nbProjects += 1;
  });

  return result;
};

export const isNotEmpty = (s?: Maybe<string>): boolean => (s ? s.length > 0 : false);

export const validateEmail = (email: string): boolean => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) !== null
  );
};
export interface FormattedPhoneNumber {
  phoneNumber: string;
  isCorrect: boolean;
  maxLength: number;
}

export const checkAndFormatPhoneNumber = (phoneNumber: string): FormattedPhoneNumber => {
  let result = {
    phoneNumber,
    isCorrect: false,
    maxLength: 17,
  };

  // France : 0 or +33
  if (phoneNumber?.startsWith("0") && phoneNumber.length === 10) {
    result = {
      phoneNumber: `${phoneNumber.substring(0, 2)} ${phoneNumber.substring(2, 4)} ${phoneNumber.substring(
        4,
        6,
      )} ${phoneNumber.substring(6, 8)} ${phoneNumber.substring(8, 10)}`,
      maxLength: 14,
      isCorrect: true,
    };
  }
  if (phoneNumber?.startsWith("+33") && phoneNumber.length === 12) {
    result = {
      phoneNumber: `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 6)} ${phoneNumber.substring(
        6,
        8,
      )} ${phoneNumber.substring(8, 10)} ${phoneNumber.substring(10, 12)}`,
      maxLength: 16,
      isCorrect: true,
    };
  }

  // Guadeloupe : +590
  // Réunion : +262
  // Guyanne : +594
  // Martinique : +596
  // Mayotte : +262
  if (
    (phoneNumber?.startsWith("+590") ||
      phoneNumber?.startsWith("+262") ||
      phoneNumber?.startsWith("+594") ||
      phoneNumber?.startsWith("+596")) &&
    phoneNumber.length === 13
  ) {
    result = {
      phoneNumber: `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 7)} ${phoneNumber.substring(
        7,
        9,
      )} ${phoneNumber.substring(9, 11)} ${phoneNumber.substring(11, 13)}`,
      maxLength: 17,
      isCorrect: true,
    };
  }

  // Luxembourg : +352
  if (phoneNumber?.startsWith("+352") && phoneNumber.length === 12)
    result = {
      phoneNumber: `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 6)} ${phoneNumber.substring(
        6,
        9,
      )} ${phoneNumber.substring(9, 12)}`,
      maxLength: 15,
      isCorrect: true,
    };

  // Suisse : +41
  if (phoneNumber?.startsWith("+41") && phoneNumber.length === 12)
    result = {
      phoneNumber: `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 5)} ${phoneNumber.substring(
        5,
        8,
      )} ${phoneNumber.substring(8, 10)} ${phoneNumber.substring(10, 12)}`,
      maxLength: 16,
      isCorrect: true,
    };

  // Belgique : +32
  if (phoneNumber?.startsWith("+32") && phoneNumber.length === 11)
    result = {
      phoneNumber: `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 4)} ${phoneNumber.substring(
        4,
        7,
      )} ${phoneNumber.substring(7, 9)} ${phoneNumber.substring(9, 11)}`,
      maxLength: 15,
      isCorrect: true,
    };

  return result;
};

export const isSignerComplete = (signer: Signer): boolean =>
  isNotEmpty(signer.gender) &&
  isNotEmpty(signer.firstName) &&
  isNotEmpty(signer.lastName) &&
  isNotEmpty(signer.email) &&
  validateEmail(signer.email || "") &&
  isNotEmpty(signer.phone) &&
  checkAndFormatPhoneNumber(signer?.phone || "").isCorrect &&
  isNotEmpty(signer.role) &&
  isNotEmpty(signer.company.name) &&
  isNotEmpty(signer.company.siret) &&
  signer.company.siret.length === 14 &&
  isNotEmpty(signer.company.address) &&
  isNotEmpty(signer.company.zipCode) &&
  signer.company.zipCode?.length === 5 &&
  isNotEmpty(signer.company.city) &&
  isNotEmpty(signer.company.companyType) &&
  isNotEmpty(signer.company.rcs) &&
  isNotEmpty(signer.company.shareCapital);

const domTomDepartments = ["97", "98"];

export const getMetDomTom = (zipCode: string): number => {
  const dep = zipCode.substring(0, 2);
  if (domTomDepartments.indexOf(dep) !== -1) return 2;
  return 1;
};

export const getMongoNow = (): number => Math.round(new Date().getTime() / 1000);
