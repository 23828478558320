import { CorporateFare } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { CSSProperties, FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../data/contexts/ProjectContext";
import { companyToCompanyInput } from "../data/dataConvertors";
import { CompanyInput } from "../data/generated/graphql";
import CenteredContent from "../utils/CenteredContent";
import { log, Lvl } from "../utils/log";
import { TextMaskSiret } from "../utils/TextMasks";

interface SiretInputProps {
  siret: string;
  setSiret: (newSiret: string) => void;
  onCompanyFetched: (company: CompanyInput) => void;
  inputStyle?: CSSProperties;
  fullWidth?: boolean;
  className?: string;
  readOnly?: boolean;
}

const SiretInput: FC<SiretInputProps> = ({
  siret,
  setSiret,
  onCompanyFetched,
  inputStyle,
  fullWidth,
  className,
  readOnly,
}) => {
  const [companyLoading, setCompanyLoading] = useState(false);
  const { getCompanyFromSiret } = useContext(ProjectContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const handleFetch = async (): Promise<void> => {
    setCompanyLoading(true);
    try {
      const company = await getCompanyFromSiret(siret);
      if (company) {
        onCompanyFetched(companyToCompanyInput(company));
      } else {
        enqueueSnackbar(t("companyNotFound"), { variant: "error" });
      }
    } catch (err) {
      log("Company fetch failed", Lvl.ERROR, err);
      enqueueSnackbar(t((err as Error).message === "Siret not found" ? "companyNotFound" : "inseeDown"), {
        variant: "error",
      });
    } finally {
      setCompanyLoading(false);
    }
  };

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <InputLabel htmlFor="company-siret-input">{t("project:siret")}</InputLabel>
      <OutlinedInput
        id="company-siret-input"
        style={inputStyle}
        label={t("project:siret")}
        value={siret}
        onChange={(e) => setSiret(e.target.value)}
        inputComponent={TextMaskSiret as any}
        autoComplete="off"
        endAdornment={
          readOnly ? undefined : (
            <InputAdornment position="end">
              {companyLoading ? (
                <div style={{ width: 40 }}>
                  <CenteredContent loading loadingSize={20} />
                </div>
              ) : (
                <Tooltip title={t(siret.length !== 14 ? "siretIsIncorrect" : "fetchCompanyBySiret") as string}>
                  <span>
                    <IconButton onClick={handleFetch} disabled={siret.length !== 14}>
                      <CorporateFare />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </InputAdornment>
          )
        }
        disabled={readOnly}
      />
    </FormControl>
  );
};

SiretInput.defaultProps = { inputStyle: undefined, fullWidth: undefined, className: undefined, readOnly: false };

export default SiretInput;
