import { Tooltip, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { checkAndFormatPhoneNumber, validateEmail } from "../../data/dataConvertors";
import { formatSiret, formatValue } from "../../data/forms/formsTypes";
import { Gender, SignerInput } from "../../data/generated/graphql";

interface SignerDetailsProps {
  signer: SignerInput;
  className?: string;
}

const SignerDetailsReadOnly: FC<SignerDetailsProps> = ({ signer, className }) => {
  const { t } = useTranslation(["user", "project"]);

  const getPhoneNumber = (): string => {
    return checkAndFormatPhoneNumber(signer?.phone || "").phoneNumber;
  };

  const getNumber = (
    nb: string,
    errorLabel: string,
    length?: number,
    formatter?: (input?: string) => string,
  ): ReactNode => {
    const formatted = formatter?.(nb) || nb;
    if (
      (nb.length !== length && typeof length === "number") ||
      (formatted.length === 0 && typeof formatter === "function")
    )
      return (
        <Tooltip title={t(errorLabel) as string}>
          <b>
            {formatted} ({t(`project:${nb.length === 0 ? "missing" : "badFormat"}`)})
          </b>
        </Tooltip>
      );
    return formatted;
  };
  const getEmail = (email: string): ReactNode => {
    if (!validateEmail(email))
      return (
        <Tooltip title={t("emailIsIncorrect") as string}>
          <b>{email}</b>
        </Tooltip>
      );
    return email;
  };
  return (
    <div className={className}>
      <Typography className="signer-form-info">
        {t("project:fullName")}{" "}
        {signer.gender && signer.firstName && signer.lastName ? (
          `${t(signer.gender === Gender.M ? "civ.mr" : "civ.mrs")} ${signer.firstName} ${signer.lastName}`
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("email")}
        {" : "}
        {signer.email ? getEmail(signer.email) : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("phone")}
        {" : "}
        {signer.phone ? (
          getNumber(signer.phone, "phoneIsIncorrect", undefined, getPhoneNumber)
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:role")} {signer.role ? signer.role : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyName")} {signer.company.name ? signer.company.name : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyType")}{" "}
        {signer.company.companyType ? signer.company.companyType : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:shareCapital")}{" "}
        {signer.company.shareCapital ? (
          `${formatValue(signer.company.shareCapital, true)} €`
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:rcs")} {signer.company.rcs ? signer.company.rcs : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("siret")} :{" "}
        {signer.company.siret ? (
          getNumber(signer.company.siret, "siretIsIncorrect", 14, formatSiret)
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyAddress")}{" "}
        {signer.company.address && signer.company.zipCode && signer.company.city ? (
          <>
            {signer.company.address}, {getNumber(signer.company.zipCode, "zipIsIncorrect", 5)} {signer.company.city}
          </>
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
    </div>
  );
};

SignerDetailsReadOnly.defaultProps = { className: undefined };

export default SignerDetailsReadOnly;
