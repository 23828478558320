import { createContext, FC, PropsWithChildren, useState } from "react";
import { signerToSignerInput } from "../dataConvertors";
import { Project, Signer } from "../generated/graphql";

interface ClientSigner {
  clientSiret: string;
  clientSigner: Signer;
}

export interface ClientContextProps {
  clients: Signer[];
  clientSigners: ClientSigner[];
  extractClients: (projects: Project[]) => void;
}

const initialContext: ClientContextProps = {
  clients: [],
  clientSigners: [],
  extractClients: () => {},
};

export const ClientContext = createContext<ClientContextProps>(initialContext);

export const ClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const [clients, setClients] = useState<Signer[]>([]);
  const [clientSigners, setClientSigners] = useState<ClientSigner[]>([]);

  const extractClients = (projects: Project[]): void => {
    const projCpy = [...projects];
    projCpy.sort((p1, p2) => {
      if ((p1.client?.company?.siret || "") > (p2.client?.company?.siret || "")) return 1;
      if ((p1.client?.company?.siret || "") < (p2.client?.company?.siret || "")) return -1;
      if (p1.clientSigner && !p2.clientSigner) return -1;
      if (!p1.clientSigner && p2.clientSigner) return 1;
      if (p1.client?.phone && !p2.client?.phone) return -1;
      if (!p1.client?.phone && p2.client?.phone) return 1;
      if (p1.reference && !p2.reference) return -1;
      if (!p1.reference && p2.reference) return 1;
      return 1;
    });
    const sirets: string[] = [];
    const dedupClients: Signer[] = [];
    const dedupClientSigners: ClientSigner[] = [];
    projCpy.forEach((p) => {
      if (sirets.indexOf(p.client?.company?.siret as string) === -1) {
        sirets.push(p.client?.company?.siret as string);
        dedupClients.push(p.client as Signer);
        if (p.clientSigner)
          dedupClientSigners.push({
            clientSiret: p.client?.company.siret || "",
            clientSigner: signerToSignerInput(p.clientSigner as Signer),
          });
      }
    });
    // Dedup
    setClients(
      dedupClients
        .sort((c1, c2) => {
          if (c1.company.name && c2.company.name && c1.company.name > c2.company.name) return 1;
          return -1;
        })
        .map((c) => signerToSignerInput(c)),
    );
    setClientSigners(dedupClientSigners);
  };

  return (
    <ClientContext.Provider
      value={{
        clients,
        clientSigners,
        extractClients,
      }}>
      {children}
    </ClientContext.Provider>
  );
};
