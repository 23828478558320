/* eslint react/require-default-props: "off" */
import { Input } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { AttachmentType } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";

export interface UploadFileButtonProps {
  projectId: string;
  label: string;
  type: AttachmentType;
  className?: string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  fullWidth?: boolean;
  onAfterUpload?: () => void;
  buttonId?: string;
  operationId?: string;
}

const UploadFileButton: FC<UploadFileButtonProps> = ({
  projectId,
  label,
  type,
  className,
  color,
  fullWidth,
  onAfterUpload,
  buttonId,
  operationId,
}) => {
  const { uploadFile } = useContext(ProjectContext);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const upload = async (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const { files } = e.target as HTMLInputElement;
    if (!files || files.length === 0) return;
    setUploading(true);
    const file = files[0];
    const result = await uploadFile(projectId, file, type, undefined, operationId);
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
      onAfterUpload?.();
    }
    setUploading(false);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={buttonId || `upload-button-file-${type}`}>
        <Input
          id={buttonId || `upload-button-file-${type}`}
          type="file"
          style={{ display: "none" }}
          onChange={upload}
          inputProps={{
            accept:
              ".pdf,.doc,.docx,.png,.jpg,.jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg",
          }}
        />
        <GdButton
          component="span"
          width="large"
          isLoading={uploading}
          label={label}
          className={className}
          color={color}
          fullWidth={fullWidth}
        />
      </label>
    </>
  );
};

export default UploadFileButton;
