import { Configuration, LogLevel } from "@azure/msal-browser";
import { UserType } from "../data/generated/graphql";
import { log, Lvl } from "../utils/log";

const LOCAL_VINCI_TYPE_KEY = "LOCAL_VINCI_TYPE_KEY";

const getClientId = (userType: UserType): string => {
  switch (userType) {
    case UserType.Construction:
      return process.env.REACT_APP_AZUREAD_APP_CLIENT_ID_CONSTRUCTION || "";
    default:
      return process.env.REACT_APP_AZUREAD_APP_CLIENT_ID || "";
  }
};

const getTenantId = (userType: UserType): string => {
  switch (userType) {
    case UserType.Construction:
      return process.env.REACT_APP_AZUREAD_AUTHORITY_ID_CONSTRUCTION || "";
    default:
      return process.env.REACT_APP_AZUREAD_AUTHORITY_ID || "";
  }
};

const getMsalConfig = (userType: UserType): Configuration => ({
  auth: {
    clientId: getClientId(userType),
    authority: `https://login.microsoftonline.com/${getTenantId(userType)}`,
    redirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (!containsPii) {
          switch (level) {
            case LogLevel.Error:
              log("MSAL Error", Lvl.ERROR, message);
              break;
            case LogLevel.Info:
              log(message, Lvl.INFO);
              break;
            case LogLevel.Verbose:
              log(message, Lvl.DEBUG);
              break;
            case LogLevel.Warning:
              log(message, Lvl.WARN);
              break;
            default:
              log(message);
              break;
          }
        }
      },
    },
  },
});

export default getMsalConfig;

export const MSAL_DEFAULT_SCOPES = { scopes: ["User.Read"] };

export const getLocalUserType = (): UserType => {
  const userType = localStorage.getItem(LOCAL_VINCI_TYPE_KEY);
  if (userType) return userType as UserType;
  return UserType.Energies;
};

export const setLocalUserType = (userType: UserType): void => localStorage.setItem(LOCAL_VINCI_TYPE_KEY, userType);

export const getCurrentTenantId = (): string => getTenantId(getLocalUserType());
