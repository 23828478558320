import { FC } from "react";
import Theme from "../Theme";

interface PaginationPros {
  pageCount: number;
  currentPage: number;
  getNewPage: (p: number) => void;
}

const Pagination: FC<PaginationPros> = ({ pageCount, currentPage, getNewPage }) => {
  const pages = [];
  for (let i = 0; i < pageCount; i += 1) {
    pages.push(i);
  }

  const selectedStyle = {
    cursor: "pointer",
    color: "white",
    background: Theme.palette.primary.main,
    borderRadius: "25px",
    padding: "0 7px",
  };

  const style = {
    cursor: "pointer",
    padding: "0 7px",
  };

  return (
    <div
      className="row margin-top"
      style={{
        width: "100%",
        justifyContent: "center",
        gap: "8px",
        color: Theme.palette.primary.main,
        justifyItems: "flex-end",
      }}>
      {pages.map((p) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          key={p}
          onClick={() => getNewPage(p)}
          onKeyDown={() => getNewPage(p)}
          style={p === currentPage ? selectedStyle : style}>
          {p + 1}
        </div>
      ))}
    </div>
  );
};

export default Pagination;
