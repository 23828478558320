import { Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../data/contexts/UserContext";
import { AttachmentType, Document, Operation, Project, ValidationStatus } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "../components/UploadFileButton";
import WarningCard from "../../utils/WarningCard";
import CenteredContent from "../../utils/CenteredContent";

interface SwornStatementProps {
  project: Project;
}

interface SwornStatementItemProps {
  project: Project;
  operation?: Operation;
  swornStatement?: Document;
}

const SwornStatementItem: FC<SwornStatementItemProps> = ({ project, operation, swornStatement }) => {
  const { t } = useTranslation("project");
  const { removeAction } = useContext(UserContext);
  const swornStatementExists =
    swornStatement?.details?.completedFilesUrl && swornStatement.details.completedFilesUrl.length > 0;
  const swornStatementValidation = swornStatement?.validation?.status;
  let labelKey = swornStatement ? "swornStatementInit" : "swornStatementNotInit";
  if (swornStatementExists) {
    if (swornStatementValidation) {
      labelKey =
        swornStatementValidation === ValidationStatus.Accepted ? "swornStatementValidated" : "swornStatementRefused";
    } else labelKey = "swornStatementValidationOngoing";
  }
  return (
    <div className="big-margin-bottom">
      <Typography variant="h6" className="text-center margin-bottom text-with-returns">
        {operation ? `${t("operation")} ${operation.id} (${operation.formId})` : t("swornStatementSoloTitle")}
      </Typography>
      <Typography className="text-center margin-bottom text-with-returns">
        {t(labelKey, { replace: { reason: swornStatement?.validation?.comment } })}
      </Typography>
      {!swornStatement ? undefined : (
        <GdButton
          className="margin-bottom"
          label={t(
            swornStatementValidation === ValidationStatus.Accepted ? "dlSwornStatement" : "dlSwornStatementModel",
          )}
          color={swornStatementValidation === ValidationStatus.Accepted ? "primary" : "secondary"}
          onClick={() => {
            if (swornStatementValidation === ValidationStatus.Accepted)
              window.open(
                swornStatement?.details?.completedFilesUrl
                  ? `${swornStatement?.details?.completedFilesUrl[0]}?t=${new Date().getTime()}`
                  : "",
              );
            else window.open(swornStatement?.modelUrl ? `${swornStatement?.modelUrl}?t=${new Date().getTime()}` : "");
          }}
        />
      )}
      {!swornStatement ||
      (swornStatementExists && swornStatementValidation !== ValidationStatus.Rejected) ||
      project.isReadOnly ? undefined : (
        <UploadFileButton
          projectId={project.id}
          label={t("ulSwornStatement")}
          type={AttachmentType.SwornStatement}
          onAfterUpload={() => removeAction(project.id)}
          buttonId={`upload-ss-${operation?.id || "solo"}`}
          operationId={operation?.id || "solo"}
        />
      )}
    </div>
  );
};

SwornStatementItem.defaultProps = { swornStatement: undefined, operation: undefined };

const SwornStatement: FC<SwornStatementProps> = ({ project }) => {
  const { t } = useTranslation("project");
  const multipleSS = project.onlyOneSwornStatement !== true && (project.swornStatements?.length || 0) > 0;
  return (
    <>
      <WarningCard
        className="margin-bottom"
        bodyData={{
          titleLabelKey: "swornStatementWarningTitle",
          content: <span className="text-with-returns">{t("swornStatementWarning")}</span>,
        }}
        warning
      />
      {multipleSS ? (
        project.operations?.map((o) => (
          <SwornStatementItem
            project={project}
            swornStatement={project.swornStatements?.find((ss) => ss.id === o?.id)}
            operation={o as Operation}
            key={`ss-${o?.id}`}
          />
        ))
      ) : project.onlyOneSwornStatement === true ? (
        <SwornStatementItem
          project={project}
          swornStatement={project.swornStatements?.find((ss) => ss.id === "solo")}
          key="ss-solo"
        />
      ) : (
        <CenteredContent infoText={t("swornStatementNotInit")} className="big-margin-top" />
      )}
    </>
  );
};

export default SwornStatement;
