import { Checkbox, FormControlLabel, Input, Modal, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { UserContext } from "../../data/contexts/UserContext";
import { getFormsForSector } from "../../data/forms/FormHelper";
import { Form, getAttachments } from "../../data/forms/formsTypes";
import { AttachmentType, DataFieldInput, Project } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import WarningCard from "../../utils/WarningCard";

export interface AddAttachmentModalProps {
  project: Project;
  open: boolean;
  onClose: () => void;
}

const AddAttachmentModal: FC<AddAttachmentModalProps> = ({ project, open, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [concerns, setConcerns] = useState<string[]>([]);
  const { uploading, uploadFile } = useContext(ProjectContext);
  const { removeAction } = useContext(UserContext);
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFile(null);
    setConcerns([]);
  }, [open]);

  const onValidate = async (): Promise<void> => {
    if (!file) return;
    const result = await uploadFile(project.id, file, AttachmentType.Other, concerns);
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
      removeAction(project.id);
      onClose();
    }
  };
  const upload = async (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const { files } = e.target as HTMLInputElement;
    if (!files || files.length === 0) return;
    setFile(files[0]);
  };

  const forms = getFormsForSector(project.details?.sector, project.client?.company?.zipCode || "");
  const checkInvoice = (checked: boolean): void => {
    setConcerns(checked ? [...concerns, "invoice"] : concerns.filter((c) => c !== "invoice"));
  };
  const checkOther = (checked: boolean): void => {
    setConcerns(checked ? [...concerns, "other"] : concerns.filter((c) => c !== "other"));
  };
  const checkCpeProof = (opId: string, checked: boolean): void => {
    const concern = `${opId}_cpeProof`;
    if (checked) setConcerns([...concerns, concern]);
    else setConcerns(concerns.filter((c) => c !== concern));
  };
  const check = (opId: string, attId: string, checked: boolean): void => {
    const concern = `${opId}_${attId}`;
    if (checked) setConcerns([...concerns, concern]);
    else setConcerns(concerns.filter((c) => c !== concern));
  };

  return (
    <Modal open={open} onClose={onClose} className="project-modal-root names-edit">
      <Paper
        className="project-modal-paper"
        style={{ width: 650, maxHeight: window.innerHeight > 1000 ? 750 : 650, overflowY: "auto" }}>
        <Typography className="margin-bottom" variant="h6">
          {t("addAttachment")}
        </Typography>
        <WarningCard warning projectLabelKey="attachmentsReadForm" className="margin-bottom" />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="upload-button-file">
          <Input
            id="upload-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={upload}
            inputProps={{
              accept:
                ".pdf,.doc,.docx,.png,.jpg,.jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg",
            }}
          />
          <GdButton component="span" width="large" label={t("selectFile")} />
        </label>
        <Typography className="text-center" style={{ marginTop: 4, overflowWrap: "anywhere" }}>
          {file ? file.name : " "}
        </Typography>
        <div>
          <FormControlLabel
            control={<Checkbox onChange={(_, checked) => checkInvoice(checked)} />}
            label={<Typography variant="h6">{t("invoice")}</Typography>}
          />
          {project.operations?.map((o) => {
            const form = forms.find((f) => f.id === o?.formId);
            const attachments = form ? getAttachments(form as Form, o?.data as DataFieldInput[]) : [];
            const op = `${t("operation")} ${o?.id} - ${o?.formId}
            ${o?.machineName ? ` (${o?.machineName})` : ""}`;
            return (
              <div key={o?.id}>
                <Typography className="margin-top" variant="h6">
                  {op}
                </Typography>
                <FormControlLabel
                  control={<Checkbox onChange={(_, checked) => check(o?.id || "", "p", checked)} />}
                  label={t("doneProof")}
                />
                {attachments.map((a, i) => (
                  <FormControlLabel
                    key={a}
                    control={<Checkbox onChange={(_, checked) => check(o?.id || "", `${i}`, checked)} />}
                    label={a}
                  />
                ))}
                {o?.isCpeScoped ? (
                  <FormControlLabel
                    control={<Checkbox onChange={(_, checked) => checkCpeProof(o?.id || "", checked)} />}
                    label={t("cpeProof")}
                  />
                ) : undefined}
              </div>
            );
          })}
          <div className="margin-top">
            <FormControlLabel
              control={<Checkbox onChange={(_, checked) => checkOther(checked)} />}
              label={<Typography variant="h6">{t("other")}</Typography>}
            />
          </div>
        </div>
        <GdButton
          label={t("global:validate")}
          onClick={onValidate}
          isLoading={uploading}
          disabled={!file || concerns.length === 0}
        />
      </Paper>
    </Modal>
  );
};

export default AddAttachmentModal;
