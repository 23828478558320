export interface ConsolidatedComputation {
  capacity: number;
  valuation: number;
  nbProjects: number;
}

export enum JustificationType {
  zni = "zni",
  cdp = "cdp",
  other = "other",
}
