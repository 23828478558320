import { AccountCircle, PlaylistAdd, PlaylistAddCheck } from "@mui/icons-material";
import { Card, CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Theme from "../Theme";
import { ConversationQa, FeedbackConversationQa } from "../data/generated/graphql";
import AssistantFeedback from "./AssistantFeedback";

const AssistantQA: FC<{
  qa: ConversationQa;
  id: string;
  loading?: boolean;
  inChat?: boolean;
  isLastQuestion?: boolean;
}> = ({ qa, id, loading, inChat, isLastQuestion }) => {
  const { t } = useTranslation("aiAssistant");
  return (
    <Card key={qa.date} className="qa-container">
      <div className="question">
        <AccountCircle color="disabled" />
        <Typography>{qa.question}</Typography>
      </div>
      <div className="answer">
        {isLastQuestion && loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Typography color={Theme.palette.primary.main} component="div">
              <Markdown>{qa.answer}</Markdown>
              {typeof qa.longAnswer === "string" ? (
                <>
                  <div className="row margin-top margin-bottom" style={{ fontStyle: "normal", color: "#555" }}>
                    <PlaylistAdd />
                    <Typography sx={{ fontWeight: "bold" }}>{t("longAnswer")}</Typography>
                  </div>
                  <Markdown>{qa.longAnswer}</Markdown>
                </>
              ) : undefined}
              {typeof qa.humanAnswer === "string" && qa.humanAnswer.length > 0 ? (
                <>
                  <div className="row margin-top margin-bottom" style={{ fontStyle: "normal", color: "#555" }}>
                    <PlaylistAddCheck />
                    <Typography sx={{ fontWeight: "bold" }}>{t("humanAnswer")}</Typography>
                  </div>
                  <Markdown>{qa.humanAnswer}</Markdown>
                </>
              ) : undefined}
            </Typography>
            <AssistantFeedback
              conversationId={id}
              qaId={qa.id}
              feedback={qa.userFeedback || FeedbackConversationQa.Neutral}
              showTellMeMore={inChat === true && isLastQuestion === true && typeof qa.longAnswer !== "string"}
            />
          </>
        )}
      </div>
    </Card>
  );
};

AssistantQA.defaultProps = { loading: false, inChat: false, isLastQuestion: false };

export default AssistantQA;
