import { Card, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MemberType, useProjectGetSignatureUrlQuery } from "../data/generated/graphql";
import { log, Lvl } from "../utils/log";

import "./Signature.css";
import { YousignEventData } from "../utils/yousignIframeSDK";
import SignatureIframe from "../common/SignatureIframe";

const Signature: FC = () => {
  const { projectId, type } = useParams() as { projectId: string; type?: string };
  const { data, error } = useProjectGetSignatureUrlQuery({
    variables: { projectId, type: type ? (type as MemberType) : MemberType.Client },
  });
  const [done, setDone] = useState(false);
  const [refused, setRefused] = useState(false);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const userRefused = (): void => {
    setRefused(true);
    setDone(true);
  };
  const userSigned = (): void => {
    setDone(true);
  };
  const onYousignError = (ysData: YousignEventData): void => {
    log("Yousign sent an error", Lvl.ERROR, ysData);
    enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
  };

  let body = <CircularProgress />;
  if (error) body = <Typography variant="h5">{t("projectNotFound")}</Typography>;
  else if (done)
    body = (
      <Typography variant="h5" className="text-with-returns">
        {t(refused ? "feedbackRefused" : type === MemberType.User ? "thankYouForSigningUser" : "thankYouForSigning")}
      </Typography>
    );
  else if (!error && data?.projectGetSignatureUrl)
    body = (
      <>
        {data?.projectGetSignatureUrl.startsWith("http") ? (
          <SignatureIframe
            signatureUrl={data?.projectGetSignatureUrl}
            onDeclined={userRefused}
            onSuccess={userSigned}
            onError={onYousignError}
            height={750}
            width={950}
          />
        ) : (
          <Typography variant="h5">{data?.projectGetSignatureUrl}</Typography>
        )}
      </>
    );

  return (
    <div className="signature-root">
      <Card elevation={3} className="signature-card">
        <div className="signature-info">
          <img src="/logo.png" alt="Greendeed logo" className="signature-logo" />
          <Typography className="signature-title" variant="h5">
            {t("signature")}
          </Typography>
        </div>
        <div className="signature-iframe">{body}</div>
      </Card>
    </div>
  );
};

export default Signature;
