import { useMsal } from "@azure/msal-react";
import { Button, Card, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SsoContext } from "../../data/contexts/SsoContext";
import { UserContext } from "../../data/contexts/UserContext";
import { UserType } from "../../data/generated/graphql";
import Authenticate from "../Authenticate/Authenticate";

import "./Unknown.css";

interface UnknownProps {
  anonymous: boolean;
  forceRetryLogin: () => void;
  unauthorized?: boolean;
  userName?: string;
  error?: boolean;
}

const UnknownDefaultProps = {
  userName: "",
  unauthorized: false,
  error: false,
};

const Unknown: FC<UnknownProps> = ({ anonymous, forceRetryLogin, unauthorized, userName, error }) => {
  const [authLoading, setAuthLoading] = useState(false);
  const { createUser } = useContext(UserContext);
  const { changeType, type, changedType } = useContext(SsoContext);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();

  useEffect(() => {
    if (changedType) setTimeout(() => setAuthLoading(true), 200);
  }, [changedType]);

  const setType = (newType: UserType): void => {
    if (type === newType) setAuthLoading(true);
    else {
      changeType(newType);
      setTimeout(() => setAuthLoading(true), 200);
    }
  };

  const authButton = authLoading ? (
    <Authenticate onError={() => setAuthLoading(false)} />
  ) : (
    <>
      <Typography variant="h5">{t("authenticateLabel")}</Typography>
      <div>
        <Button variant="contained" className="margin-bottom" onClick={() => setType(UserType.Energies)}>
          <span style={{ display: "flex", flexDirection: "column", margin: "16px 8px" }}>
            <img
              src="/logo_vinci_energies.svg"
              alt="Logo VINCI Energies"
              style={{ backgroundColor: "#fff", padding: "8px", width: "300px", borderRadius: "4px" }}
            />
          </span>
        </Button>
        <Button variant="contained" onClick={() => setType(UserType.Construction)}>
          <span style={{ display: "flex", flexDirection: "column", margin: "16px 8px" }}>
            <img
              src="/logo_vinci_construction.svg"
              alt="Logo VINCI Construction"
              style={{ backgroundColor: "#fff", padding: "8px", width: "300px", borderRadius: "4px" }}
            />
          </span>
        </Button>
      </div>
    </>
  );
  const unauthorizedButton = authLoading ? (
    <CircularProgress />
  ) : (
    <>
      <Typography variant="h5">{t("unknownLabel", { userName: userName || "" })}</Typography>
      <div style={{ position: "relative", height: 40 }}>
        <Button
          variant="contained"
          onClick={() => {
            setAuthLoading(true);
            const create = async (): Promise<void> => {
              const ok = await createUser(type);
              if (!ok) {
                enqueueSnackbar(t("userCreationFailed"), { variant: "error" });
                setAuthLoading(false);
              }
            };
            create();
          }}>
          {t("unknownButton")}
        </Button>
      </div>
    </>
  );

  return (
    <div className="anonymous-root">
      <Card elevation={3} className="anonymous-card">
        <img src="/logo.png" alt="Greendeed logo" className="anonymous-logo" style={{ padding: "32px 0" }} />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-evenly",
            paddingBottom: "32px",
          }}>
          {unauthorized ? (
            <>
              <Typography variant="h5">{t("unauthorized")}</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  instance.logout();
                }}>
                {t("logOut")}
              </Button>
            </>
          ) : error ? (
            <>
              <Typography variant="h5">{t("unkownErrorForce")}</Typography>
              <Button variant="contained" onClick={forceRetryLogin}>
                {t("forceRetryLogin")}
              </Button>
            </>
          ) : anonymous ? (
            authButton
          ) : (
            unauthorizedButton
          )}
        </div>
      </Card>
    </div>
  );
};

Unknown.defaultProps = UnknownDefaultProps;

export default Unknown;
