import {
  Card,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Prompt } from "react-router-dom";
import { dataDateConvertToDate, dateConvertToDataDate, detailsToDetailsInput } from "../../data/dataConvertors";
import { Project, ProjectDetailsInput, Sector } from "../../data/generated/graphql";
import { activityCodeToSector, getSectorsName } from "../../utils/constants";
import GdButton from "../../utils/GdButton";
import GdDatePicker from "../../utils/GdDatePicker";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { log, Lvl } from "../../utils/log";
import WarningCard from "../../utils/WarningCard";
import { TextMaskNumber } from "../../utils/TextMasks";

import "./DetailsSelector.css";
import ModifyButton from "../components/ModifyButton";

interface DetailsSelectorProps {
  project: Project;
  nextStep: (event?: React.MouseEvent) => void;
  readOnly: boolean;
}

export const defaultDetails: ProjectDetailsInput = {
  isMain: true,
  startDate: "",
  endDate: "",
};

const DetailsSelector: FC<DetailsSelectorProps> = ({ project, nextStep, readOnly }) => {
  const projectId = project.id;
  const details = project.details || undefined;
  const mainActivityCode = project.client?.company?.mainActivityCode || undefined;
  const [newDetails, setNewDetails] = useState(details ? detailsToDetailsInput(details) : defaultDetails);
  const [updating, setUpdating] = useState(false);
  const [modified, setModified] = useState(false);
  const [mainSector, setMainSector] = useState<Sector | null>(null);
  const [cpe, setCpe] = useState<boolean>(details ? Boolean(details.cpeDuration) : false);
  const { t } = useTranslation(["project", "global"]);
  const { updateProject, updateError } = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setNewDetails(details ? detailsToDetailsInput(details) : defaultDetails);
  }, [details]);
  useEffect(() => {
    setMainSector(mainActivityCode ? activityCodeToSector(mainActivityCode as string) : null);
  }, [mainActivityCode]);

  const modifySomething = (key: string, value: string | boolean | undefined): void => {
    if (newDetails[key as keyof ProjectDetailsInput] !== value) {
      setNewDetails({ ...newDetails, [key]: value });
      setModified(true);
    }
  };
  const modifyIsMain = (e: ChangeEvent<HTMLInputElement>): void => modifySomething("isMain", e.target.value === "yes");
  const selectedSector = newDetails.isMain && mainSector !== null ? mainSector : newDetails.sector;
  const validate = async (): Promise<void> => {
    setUpdating(true);
    const result = await updateProject({
      id: projectId,
      details: { ...newDetails, sector: selectedSector },
    });
    if (!result) {
      log("Error while updating project", Lvl.ERROR, updateError);
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
      setModified(false);
      nextStep();
    }
    setUpdating(false);
  };

  const sectors = getSectorsName();
  const errorCpePercentage =
    newDetails.cpePercentage &&
    newDetails.cpePercentage.length > 0 &&
    parseInt(newDetails.cpePercentage || "0", 10) < 20;
  const errorCpeDuration =
    newDetails.cpeDuration && newDetails.cpeDuration.length > 0 && parseInt(newDetails.cpeDuration, 10) < 5;

  return (
    <div className="project-details-root">
      <Typography className="project-details-info">
        {t("clientMainActivity")} <b>{mainActivityCode}</b>
      </Typography>
      <div className="row project-details-info">
        <Typography>
          {t("correspondingSector")} <b>{t(`sectors.${mainSector}`)}</b>
        </Typography>
        <Tooltip title={<span>{t("sectorTooltip")}</span>} arrow placement="top">
          <IconButton style={{ marginLeft: 4 }}>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </div>
      {mainSector === null ? undefined : (
        <>
          <Typography>{t("isMainActivity")}</Typography>
          <FormControl disabled={readOnly} component="fieldset" className="project-main-sector-radios">
            <RadioGroup row onChange={modifyIsMain}>
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={t("global:yesOrNo.yes")}
                checked={newDetails?.isMain || false}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t("global:yesOrNo.no")}
                checked={!newDetails.isMain}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      {!newDetails.isMain || mainSector === null ? (
        <Card className="project-sector-selector" variant="outlined">
          <Typography className="project-sector-label">{t("projectSector")}</Typography>
          <Select
            value={newDetails.sector ? newDetails.sector : undefined}
            onChange={(e) => {
              modifySomething("sector", e.target.value ? (e.target.value as Sector) : undefined);
            }}
            variant="outlined"
            fullWidth
            disabled={readOnly}
            style={{ textAlign: "left" }}>
            <MenuItem value={undefined}>
              <em>{t("global:makeSelection")}</em>
            </MenuItem>
            {sectors.map((s) => (
              <MenuItem value={s} key={s}>
                {t(`sectors.${s}`)}
              </MenuItem>
            ))}
          </Select>
        </Card>
      ) : undefined}
      <div className="two-fields-row">
        <GdDatePicker
          label={t("startDate")}
          className="left-field"
          disabled={readOnly}
          minDate={readOnly ? undefined : new Date()}
          value={dataDateConvertToDate(newDetails?.startDate)}
          onChange={(d: Date | null) => {
            if (Number.isNaN(d?.getTime())) return;
            const date = dateConvertToDataDate(d);
            if (date < "1975-01-01") return;
            let { endDate } = newDetails;
            if (date && (!endDate || endDate < date)) {
              const oneWeekAfter = new Date((d?.getTime() || 0) + 7 * 24 * 3600 * 1000);
              endDate = dateConvertToDataDate(oneWeekAfter);
            }
            if (newDetails.startDate !== date || newDetails.endDate !== endDate) {
              setNewDetails({ ...newDetails, startDate: date, endDate });
              setModified(true);
            }
          }}
        />
        <GdDatePicker
          label={t("endDate")}
          className="right-field"
          disabled={readOnly}
          minDate={dataDateConvertToDate(newDetails?.startDate) || new Date()}
          value={dataDateConvertToDate(newDetails?.endDate)}
          onChange={(d) => modifySomething("endDate", dateConvertToDataDate(d))}
        />
      </div>
      {selectedSector === Sector.Bat || selectedSector === Sector.Bar ? (
        <>
          <Typography>{t("isCpe")}</Typography>
          <FormControl disabled={readOnly} component="fieldset" className="project-main-sector-radios">
            <RadioGroup
              row
              onChange={(_, value) => {
                setCpe(value === "yes");
                if (value === "no") {
                  setNewDetails({ ...newDetails, cpeDuration: undefined, cpePercentage: undefined });
                }
                setModified(true);
              }}>
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={t("global:yesOrNo.yes")}
                checked={cpe}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t("global:yesOrNo.no")}
                checked={!cpe}
              />
            </RadioGroup>
          </FormControl>
          {cpe ? (
            <>
              <div className="two-fields-row">
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  label={t("cpePercentage")}
                  value={newDetails.cpePercentage}
                  onChange={(e) => modifySomething("cpePercentage", e.target.value)}
                  className="left-field"
                  InputProps={{
                    inputComponent: TextMaskNumber as any,
                  }}
                  error={
                    newDetails.cpePercentage && newDetails.cpePercentage.length > 0
                      ? parseInt(newDetails.cpePercentage || "0", 10) < 20
                      : false
                  }
                />
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  label={t("cpeDuration")}
                  value={newDetails.cpeDuration}
                  onChange={(e) => modifySomething("cpeDuration", e.target.value)}
                  className="right-field"
                  InputProps={{
                    inputComponent: TextMaskNumber as any,
                  }}
                  error={
                    newDetails.cpeDuration && newDetails.cpeDuration.length > 0
                      ? parseInt(newDetails.cpeDuration || "0", 10) < 5
                      : false
                  }
                />
              </div>
              {errorCpeDuration || errorCpePercentage ? (
                <Typography variant="body2" color="red" className="margin-bottom text-center">
                  {errorCpePercentage ? t("errorCpePercentage") : ""}
                  {errorCpePercentage && errorCpeDuration ? <br /> : undefined}
                  {errorCpeDuration ? t("errorCpeDuration") : ""}
                </Typography>
              ) : undefined}
            </>
          ) : undefined}
        </>
      ) : undefined}
      <WarningCard projectLabelKey={["dateWarning", "cpeWarning"]} className="margin-bottom" />
      <Prompt
        when={Boolean(modified && project.operations && project.operations.length > 0)}
        message={t("promptNotSaved")}
      />
      {readOnly ? (
        project.isReadOnly ? undefined : (
          <ModifyButton project={project} projectLabelKey="modifyDetails" />
        )
      ) : (
        <GdButton
          label={t(project.operations && project.operations.length > 0 ? "global:save" : "validateNextstep")}
          onClick={validate}
          isLoading={updating}
          disabled={
            (!newDetails.isMain && !newDetails.sector) ||
            newDetails.startDate.length === 0 ||
            newDetails.endDate.length === 0 ||
            (cpe && (errorCpeDuration || errorCpePercentage || !newDetails.cpePercentage || !newDetails.cpeDuration)) ||
            !modified
          }
        />
      )}
    </div>
  );
};

export default DetailsSelector;
