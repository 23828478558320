import { Typography } from "@mui/material";
import { FC } from "react";
import BaseCard from "../utils/BaseCard";

const Forms: FC = () => (
  <BaseCard>
    <Typography>En cours de construction...</Typography>
  </BaseCard>
);

export default Forms;
