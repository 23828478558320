import { Check, Close, ContentCopy, ExpandMore, HourglassBottom } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { UserContext } from "../../data/contexts/UserContext";
import {
  dateConvertToString,
  detailsToDetailsInput,
  emptySigner,
  isSignerComplete,
  signerToSignerInput,
} from "../../data/dataConvertors";
import {
  Project,
  MemberType,
  MemberStatus,
  ProcedureStatus,
  Maybe,
  RateStatus,
  AttachmentType,
  ValidationStatus,
  UserActionType,
  DocumentType,
} from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import GdList, { GdListItem } from "../../utils/GdList";
import { log, Lvl } from "../../utils/log";
import WarningCard from "../../utils/WarningCard";
import { defaultDetails } from "./DetailsSelector";
import SignerDetails from "../components/SignerDetails";
import GdAlert from "../../utils/GdAlert";
import SignerDetailsReadOnly from "../components/SignerDetailsReadOnly";
import UploadFileButton from "../components/UploadFileButton";
import { YousignEventData } from "../../utils/yousignIframeSDK";
import SignatureIframe from "../../common/SignatureIframe";
import GdModal from "../../utils/GdModal";

interface ProjectConventionProps {
  project: Project;
}

const ProjectConvention: FC<ProjectConventionProps> = ({ project }) => {
  const [signersExpanded, setSignersExpanded] = useState(
    Boolean(!project.convention || project.convention.status === ProcedureStatus.Asked),
  );
  const { prepareConvention, optimisticUserSignedConvention, unlaunchProject, checkConvention, askForConvention } =
    useContext(ProjectContext);
  const { actions, removeAction } = useContext(UserContext);
  const [newDetails, setNewDetails] = useState(project.details || defaultDetails);
  const [userSigner, setUserSigner] = useState(
    project.userSigner ? signerToSignerInput(project.userSigner) : emptySigner,
  );
  const [client, setClient] = useState(signerToSignerInput(project.clientSigner || project.client));
  const [updating, setUpdating] = useState(false);
  const [signerOpen, setSignerOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  const [convOpen, setConvOpen] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);
  const [linkClient, setLinkClient] = useState(true);
  const [checking, setChecking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmRefusedOpen, setConfirmRefusedOpen] = useState(false);
  const [forceReadWrite, setForceReadWrite] = useState(false);
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setNewDetails(project.details ? detailsToDetailsInput(project.details) : defaultDetails);
  }, [project]);

  const getSignatureItem = (status: Maybe<MemberStatus> | undefined, resultKey: string): GdListItem => {
    let contentKey = "onGoing";
    let Icon = HourglassBottom;
    if (status === MemberStatus.Refused) {
      contentKey = "refused";
      Icon = Close;
    } else if (status === MemberStatus.Done) {
      contentKey = "signed";
      Icon = Check;
    }
    return {
      content: t(resultKey, { replace: { status: t(contentKey) } }),
      icon: <Icon />,
    };
  };

  const userJustRefused = async (): Promise<void> => {
    setConfirmRefusedOpen(true);
  };

  const doUnlaunchProject = async (): Promise<void> => {
    setLoading(true);
    await unlaunchProject();
    setLoading(false);
    setConfirmRefusedOpen(false);
  };

  const userJustSigned = async (): Promise<void> => {
    optimisticUserSignedConvention();
    setConvOpen(false);
  };

  const onYousignError = (ysData: YousignEventData): void => {
    log("Yousign sent an error", Lvl.ERROR, ysData);
    enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
  };

  useEffect(() => {
    if (
      project.convention?.details?.members &&
      actions.findIndex((a) => a.projectId === project.id && a.type === UserActionType.SignConvention) !== -1
    ) {
      const userHasSigned =
        project.convention.details.members.find((m) => m.type === MemberType.User)?.status === MemberStatus.Done;
      const clientHasSigned =
        project.convention.details.members.find((m) => m.type === MemberType.Client)?.status === MemberStatus.Done;
      if (userHasSigned && clientHasSigned) {
        removeAction(project.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const modifyPrefinancing = (e: ChangeEvent<HTMLInputElement>): void =>
    setNewDetails({ ...newDetails, preFinancing: e.target.value === "yes" });
  const modifyUserCertified = (newValue: boolean): void =>
    setNewDetails({ ...newDetails, userCertifiedSector: newValue });

  const validate = async (): Promise<void> => {
    setUpdating(true);
    const result = await prepareConvention({
      id: project.id,
    });
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    }
    setUpdating(false);
  };

  const askConvention = async (): Promise<void> => {
    setUpdating(true);
    const result = await askForConvention({
      id: project.id,
      details: newDetails,
      userSigner,
      clientSigner: client,
    });
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    }
    setUpdating(false);
  };

  const readOnly = Boolean(project.convention);
  const getConventionControls = (): ReactNode => {
    if (project.convention?.paper) {
      const conventionValidation = project.convention?.validation?.status;
      let statusKey = "ongoing";
      if (conventionValidation) {
        statusKey = conventionValidation === ValidationStatus.Accepted ? "accepted" : "refused";
      }
      return (
        <>
          <Typography variant="h6" className="text-center margin-bottom text-with-returns">
            {t("manualConvention")}{" "}
            {t(`sentAttachmentsStatus.${statusKey}`, { replace: { reason: project.convention?.validation?.comment } })}
            {conventionValidation === ValidationStatus.Rejected ? t("manualConventionRefusedSuffix") : ""}
          </Typography>
          {project.isReadOnly ? undefined : conventionValidation === ValidationStatus.Rejected ? (
            <UploadFileButton
              projectId={project.id}
              label={t("sendManualConventionAgain")}
              type={AttachmentType.Convention}
              className="margin-top"
            />
          ) : (
            <GdButton
              label={t(
                conventionValidation === ValidationStatus.Accepted
                  ? "seeManualConvention"
                  : "seeUploadedManualConvention",
              )}
              onClick={() => {
                window.open(
                  project.convention?.details?.completedFilesUrl &&
                    project.convention?.details?.completedFilesUrl.length > 0
                    ? `${project.convention?.details?.completedFilesUrl[0]}?t=${new Date().getTime()}`
                    : "",
                );
              }}
            />
          )}
        </>
      );
    }
    if (project.convention?.status === ProcedureStatus.Finished)
      return (
        <>
          <Typography variant="h6" className="text-center margin-bottom">
            {t("conventionIsFinished")}
          </Typography>
          <GdButton
            label={t("seeSignedConvention")}
            onClick={() =>
              window.open(
                project.convention?.details?.completedFilesUrl
                  ? `${project.convention?.details?.completedFilesUrl[0]}?t=${new Date().getTime()}`
                  : project.convention?.modelUrl?.replace("Convention", "signed_Convention") || "",
              )
            }
          />
        </>
      );
    if (project.convention?.status === ProcedureStatus.Asked)
      return (
        <>
          <Typography variant="h6" className="text-center margin-bottom">
            {t("conventionIsInCreation")}
          </Typography>
          {forceReadWrite || project.isReadOnly ? undefined : (
            <GdButton label={t("modifySigners")} onClick={() => setForceReadWrite(true)} />
          )}
        </>
      );
    const userSignatureStatus = project.convention?.details?.members?.find((m) => m.type === MemberType.User)?.status;
    const clientSignatureStatus = project.convention?.details?.members?.find(
      (m) => m.type === MemberType.Client,
    )?.status;
    const greendeedSignatureStatus = project.convention?.details?.members?.find(
      (m) => m.type === MemberType.Admin,
    )?.status;
    const oneRefused =
      userSignatureStatus === MemberStatus.Refused ||
      clientSignatureStatus === MemberStatus.Refused ||
      greendeedSignatureStatus === MemberStatus.Refused;
    const checkSignatures = async (): Promise<void> => {
      setChecking(true);
      const result = await checkConvention(project);
      if (!result) {
        enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
      }
      setChecking(false);
    };

    const conventionExpired = project?.convention?.status === ProcedureStatus.Expired;
    const isBipartite = project?.convention?.type === DocumentType.Bipartite;

    return (
      <>
        {conventionExpired || project.isReadOnly ? undefined : (
          <WarningCard projectLabelKey="noModificationAfterSigning" warning className="margin-bottom" />
        )}
        <Typography variant="h6" className="text-center margin-bottom">
          {t(conventionExpired ? "conventionExpiredOn" : "conventionExpiresOn", {
            replace: {
              expiration: dateConvertToString(project?.convention?.details?.expirationDate || ""),
              type: (project.convention?.type || DocumentType.Tripartite).toLowerCase(),
            },
          })}
        </Typography>
        {conventionExpired ? undefined : (
          <div className="row margin-bottom">
            <div style={{ flex: 1 }}>
              <GdList
                dense
                items={
                  isBipartite
                    ? [
                        getSignatureItem(clientSignatureStatus, "yourClientSignature"),
                        getSignatureItem(greendeedSignatureStatus, "greendeedSignature"),
                      ]
                    : [
                        getSignatureItem(userSignatureStatus, "yourSignature"),
                        getSignatureItem(clientSignatureStatus, "yourClientSignature"),
                        getSignatureItem(greendeedSignatureStatus, "greendeedSignature"),
                      ]
                }
              />
            </div>
            {project.isReadOnly ? undefined : (
              <GdButton label={t("checkSignatures")} color="inherit" onClick={checkSignatures} isLoading={checking} />
            )}
          </div>
        )}
        {oneRefused || conventionExpired ? (
          <>
            {conventionExpired ? undefined : (
              <Typography variant="h6" className="text-center margin-bottom">
                {t("conventionHasBeenRefused")}
              </Typography>
            )}
            {project.isReadOnly ? undefined : (
              <>
                <GdButton
                  label={t("prepareConventionAgain")}
                  onClick={validate}
                  className="margin-bottom convention-buttons"
                  fullWidth
                  isLoading={updating}
                />
                <GdButton
                  className="margin-bottom convention-buttons"
                  label={t("modifyProject")}
                  onClick={doUnlaunchProject}
                  color="secondary"
                  fullWidth
                  isLoading={loading}
                />
              </>
            )}
          </>
        ) : (
          <>
            {userSignatureStatus === MemberStatus.Done || isBipartite ? undefined : (
              <div className="row margin-bottom space-evenly">
                <GdButton label={t("signConvention")} onClick={() => setConvOpen(true)} />
                <GdButton
                  label={t("getUserLink")}
                  onClick={() => {
                    setLinkClient(false);
                    setLinkOpen(true);
                  }}
                  color="inherit"
                />
              </div>
            )}
            <GdButton
              className="margin-bottom convention-buttons"
              label={t("downloadConvention")}
              onClick={() =>
                window.open(
                  project.convention?.modelUrl ? `${project.convention?.modelUrl}?t=${new Date().getTime()}` : "",
                )
              }
              color="secondary"
              fullWidth
            />
            {clientSignatureStatus === MemberStatus.Done ? undefined : (
              <GdButton
                className="convention-buttons"
                label={t("getClientLink")}
                onClick={() => {
                  setLinkClient(true);
                  setLinkOpen(true);
                }}
                color="inherit"
                fullWidth
              />
            )}
          </>
        )}
      </>
    );
  };

  const isRateTemporary = project.totalComputation?.rate?.status === RateStatus.Temporary;
  const createConventionButton = isRateTemporary ? (
    <div className="text-center padding">
      <Typography>{t("conventionRateTemporary")}</Typography>
    </div>
  ) : project.isReadOnly ? undefined : (
    <GdButton
      label={t("askToGenerateConvention")}
      onClick={askConvention}
      isLoading={updating}
      disabled={!newDetails.userCertifiedSector || !isSignerComplete(userSigner) || !isSignerComplete(client)}
    />
  );
  const isBipartite = project?.convention?.type === DocumentType.Bipartite;

  return (
    <div>
      <Accordion
        className="margin-bottom"
        expanded={signersExpanded}
        onChange={() => setSignersExpanded(!signersExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">{t(isBipartite ? "signer" : "signers")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            {isBipartite ? undefined : (
              <div style={{ marginRight: 16 }} className="signer-root">
                <Typography className="margin-bottom">{t("mySigner")}</Typography>
                <Paper elevation={1} className="padding margin-bottom signer-details-root">
                  <SignerDetailsReadOnly signer={userSigner} className="margin-bottom signer-details-readonly" />
                  {(readOnly && !forceReadWrite) || project.isReadOnly ? undefined : (
                    <GdButton label={t("modifySigner")} onClick={() => setSignerOpen(true)} />
                  )}
                </Paper>
              </div>
            )}
            <div style={{ marginLeft: 16 }} className="signer-root">
              <Typography className="margin-bottom">{t("clientSigner")}</Typography>
              <Paper elevation={1} className="padding margin-bottom signer-details-root">
                <SignerDetailsReadOnly signer={client} className="margin-bottom signer-details-readonly" />
                {(readOnly && !forceReadWrite) || project.isReadOnly ? undefined : (
                  <GdButton label={t("modifySigner")} onClick={() => setClientOpen(true)} />
                )}
              </Paper>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {!project.totalComputation?.preFinanceable || project.isReadOnly ? undefined : (
        <>
          <div className="text-center">
            <Typography>{t("askPrefinancing")}</Typography>
            <FormControl disabled={readOnly} component="fieldset" className="project-main-sector-radios">
              <RadioGroup row onChange={modifyPrefinancing}>
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={t("global:yesOrNo.yes")}
                  checked={newDetails.preFinancing || false}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={t("global:yesOrNo.no")}
                  checked={!newDetails.preFinancing}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <WarningCard projectLabelKey="prefinancingNb" warning={false} className="margin-bottom" />
        </>
      )}
      {readOnly && !newDetails.userCertifiedSector ? undefined : (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={(_, checked) => modifyUserCertified(checked)}
              checked={newDetails.userCertifiedSector || false}
            />
          }
          disabled={readOnly || project.isReadOnly}
          label={t("userCertifies", { replace: { sector: t(`sectors.${newDetails.sector}`) } })}
          className="margin-bottom"
        />
      )}
      {readOnly || project.isReadOnly ? undefined : (
        <WarningCard
          projectLabelKey="allFieldMandatory"
          className="margin-bottom"
          additionalLines={
            !isSignerComplete(userSigner) || !isSignerComplete(client) ? [t("oneSignerIncomplete")] : undefined
          }
        />
      )}
      {project.convention ? getConventionControls() : createConventionButton}
      <Modal open={signerOpen} onClose={() => setSignerOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper signer-details">
          <SignerDetails
            projectId={project.id}
            signer={userSigner}
            setSigner={setUserSigner}
            onClose={() => setSignerOpen(false)}
            mySigner
          />
        </Paper>
      </Modal>
      <Modal open={clientOpen} onClose={() => setClientOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper signer-details">
          <SignerDetails
            projectId={project.id}
            signer={client}
            setSigner={setClient}
            onClose={() => setClientOpen(false)}
          />
        </Paper>
      </Modal>
      <GdModal open={convOpen} onClose={() => setConvOpen(false)}>
        <SignatureIframe
          signatureUrl={project.convention?.details?.members?.find((m) => m.type === MemberType.User)?.url || ""}
          onSuccess={userJustSigned}
          onDeclined={userJustRefused}
          onError={onYousignError}
        />
      </GdModal>
      <Dialog open={linkOpen} onClose={() => setLinkOpen(false)}>
        <DialogTitle>{t(linkClient ? "clientLink" : "userLink")}</DialogTitle>
        <DialogContent>
          <DialogContentText className="margin-bottom">
            {t(linkClient ? "hereIsTheClientLink" : "hereIsTheUserLink")}
          </DialogContentText>
          <TextField
            value={`${window.location.protocol}//${window.location.host}/sign/${project.id}${
              linkClient ? "" : "/User"
            }`}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t("copyLink") as string} arrow>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${window.location.protocol}//${window.location.host}/sign/${project.id}${
                              linkClient ? "" : "/User"
                            }`,
                          )
                          .then(
                            () => {
                              enqueueSnackbar(t(linkClient ? "clientLinkIsInClipboard" : "userLinkIsInClipboard"), {
                                variant: "success",
                              });
                            },
                            () => {
                              enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
                            },
                          );
                      }}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      </Dialog>
      <GdAlert
        body={t("youRefusedToSign")}
        title={t("global:warning")}
        open={confirmRefusedOpen}
        onClose={doUnlaunchProject}
        okButtonClick={doUnlaunchProject}
        okButtonLoading={loading}
      />
    </div>
  );
};

export default ProjectConvention;
