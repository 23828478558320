import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import CenteredContent from "../utils/CenteredContent";
import { log, Lvl } from "../utils/log";

const SigningFinished: FC = () => {
  const { status } = useParams() as { status: string };
  useEffect(() => {
    log(status, Lvl.INFO);
    window.parent.postMessage(`gd_ys:${status}`, "*");
  }, [status]);
  return <CenteredContent loading />;
};

export default SigningFinished;
