import { AppBar as MuiAppBar, Toolbar, IconButton, Typography, styled, Tooltip } from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { drawerWidth } from "./AppDrawer";
import { ProjectContext } from "../data/contexts/ProjectContext";
import GreenyMonochrome from "../utils/pictos/greeny-monochrome";
import Theme from "../Theme";
import { AssistantContext } from "../data/contexts/AssistantContext";

const OurAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, about }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(about === "opened" && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarProps {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setAnchorEl: (el: Element) => void;
}

const AppBar: FC<AppBarProps> = ({ menuOpen, setMenuOpen, setAnchorEl }) => {
  const { t } = useTranslation(["global", "aiAssistant"]);
  const { pathname } = useLocation();
  const openMenu = (): void => setMenuOpen(true);
  const { isAssistantActive, setOpenGreeny, openGreeny } = useContext(AssistantContext);
  const openAiAssistant = (): void => setOpenGreeny(!openGreeny);
  const { currentProject } = useContext(ProjectContext);
  let pathKey = pathname;
  let projectName = null;
  if (pathname.startsWith("/projects") && pathname !== "/projects/create") {
    pathKey = "/projects";
    if (currentProject && pathname.indexOf(currentProject.id) !== -1) {
      projectName = currentProject?.name;
    }
  }
  return (
    <OurAppBar position="static" about={menuOpen ? "opened" : "closed"}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={openMenu}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap style={{ flex: 1 }}>
          {t([`titles.${pathKey}`, "titles./"])}
          {projectName ? ` : ${projectName}` : ""}
        </Typography>

        <Tooltip title={t(`aiAssistant:${isAssistantActive ? "tooltip" : "subscription.callToAction"}`)} arrow>
          <IconButton
            sx={{ marginRight: "8px", borderRadius: "8px", backgroundColor: openGreeny ? "#FFF" : undefined }}
            edge="end"
            aria-controls="auth-menu"
            aria-haspopup="true"
            onClick={openAiAssistant}
            className="ai-assistant-switch"
            color="inherit">
            <GreenyMonochrome width={84} height={28} fill={openGreeny ? Theme.palette.primary.main : "#FFF"} />
          </IconButton>
        </Tooltip>

        <IconButton
          edge="end"
          aria-controls="auth-menu"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          color="inherit">
          <AccountCircle />
        </IconButton>
      </Toolbar>
    </OurAppBar>
  );
};

export default AppBar;
