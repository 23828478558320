import { Sector } from "../data/generated/graphql";

export const activityCodeToSector = (activityCode: string): Sector | null => {
  if (activityCode.startsWith("0")) return Sector.Agri;
  if (activityCode.startsWith("1") || activityCode.startsWith("2") || activityCode.startsWith("3")) return Sector.Ind;
  if (activityCode.startsWith("41") || activityCode.startsWith("42") || activityCode.startsWith("43"))
    return Sector.Bat;
  return null;
};

export const getSectorsName = (): string[] => {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const value of Object.values(Sector)) {
    result.push(value);
  }
  return result;
};
