import { createContext, FC, PropsWithChildren, useState } from "react";
import { getLocalUserType, setLocalUserType } from "../../auth/authConfig";
import { log, Lvl } from "../../utils/log";
import { UserType } from "../generated/graphql";

export interface SsoContextProps {
  changedType: boolean;
  type: UserType;
  changeType: (newType: UserType) => void;
}

const initialContext: SsoContextProps = {
  changedType: false,
  type: UserType.Energies,
  changeType: () => {},
};

export const SsoContext = createContext<SsoContextProps>(initialContext);

export const SsoProvider: FC<PropsWithChildren> = ({ children }) => {
  const localType = getLocalUserType();
  const [type, setType] = useState<UserType>(localType);
  const [changedType, setChangedType] = useState(false);

  const changeType = (newType: UserType): void => {
    if (type !== newType) {
      log("Changing type", Lvl.INFO, newType);
      setLocalUserType(newType);
      setType(newType);
      setChangedType(true);
    }
  };

  return <SsoContext.Provider value={{ changedType, type, changeType }}>{children}</SsoContext.Provider>;
};
