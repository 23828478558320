import { NavigateNext } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FC, ReactNode } from "react";

export interface GdListItem {
  icon?: ReactNode;
  content: ReactNode;
}

interface GdListProps {
  items: GdListItem[];
  dense?: boolean;
}

const GdList: FC<GdListProps> = ({ items, dense }) => (
  <List dense={dense}>
    {items.map((i) => (
      <ListItem key={JSON.stringify(i.content)}>
        <ListItemIcon>{i.icon ? i.icon : <NavigateNext />}</ListItemIcon>
        <ListItemText primary={i.content} />
      </ListItem>
    ))}
  </List>
);

GdList.defaultProps = { dense: false };

export default GdList;
