import { FC, PropsWithChildren, createContext, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Lvl, log } from "../../utils/log";
import { getMongoNow } from "../dataConvertors";
import {
  AssistantFilters,
  Claims,
  Conversation,
  FeedbackConversationQa,
  useAssistantAskForMoreMutation,
  useAssistantAskQuestionMutation,
  useAssistantDeleteConversationMutation,
  useAssistantGetUserConversationHistoryQuery,
  useAssistantQaFeedbackMutation,
} from "../generated/graphql";
import { UserContext } from "./UserContext";

const defaultLimit = 15;

export interface AssistantContextProps {
  conversationPages: Array<Conversation[]>;
  currentConversation: Conversation | undefined;
  openGreeny: boolean;
  setCurrentConversation: (newConv: Conversation | undefined) => void;
  getConversationHistory: (page: number, filters?: AssistantFilters, force?: boolean) => Promise<boolean>;
  askQuestion: (question: string) => Promise<boolean>;
  askForMore: () => Promise<boolean>;
  deleteConversation: (conversationId: string) => Promise<boolean>;
  setOpenGreeny: (b: boolean) => void;
  isAssistantActive: boolean;
  addFeedback: (conversationId: string, qaId: string, userFeedback?: FeedbackConversationQa) => Promise<boolean>;
  openGreenySubscription: boolean;
  setOpenGreenySubscription: (b: boolean) => void;
  pageCount: number;
  latestReset: number;
  currentConversationHistoryPage: number;
  loading: boolean;
}

const initialContext: AssistantContextProps = {
  conversationPages: [],
  currentConversation: undefined,
  openGreeny: false,
  setCurrentConversation: () => {},
  getConversationHistory: () => Promise.resolve(false),
  askQuestion: () => Promise.resolve(false),
  askForMore: () => Promise.resolve(false),
  deleteConversation: () => Promise.resolve(false),
  setOpenGreeny: () => {},
  isAssistantActive: false,
  addFeedback: () => Promise.resolve(false),
  openGreenySubscription: false,
  setOpenGreenySubscription: () => {},
  pageCount: 0,
  latestReset: 0,
  currentConversationHistoryPage: 0,
  loading: false,
};

export const AssistantContext = createContext<AssistantContextProps>(initialContext);

export const AssistantProvider: FC<PropsWithChildren> = ({ children }) => {
  const [conversationPages, setConversationPages] = useState<Array<Conversation[]>>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [currentConversationHistoryPage, setCurrentConversationHistoryPage] = useState<number>(0);
  const [searchedTerm, setSearchedTerm] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [currentConversation, setCurrentConversation] = useState<Conversation | undefined>();
  const [latestReset, setLatestReset] = useState(0);
  const { refetch: fetchHistory } = useAssistantGetUserConversationHistoryQuery({ skip: true, errorPolicy: "all" });
  const [askQuestionServer] = useAssistantAskQuestionMutation();
  const [latestCheck, setLatestCheck] = useState(0);
  const [openGreeny, setOpenGreeny] = useState(false);
  const [openGreenySubscription, setOpenGreenySubscription] = useState(false);
  const { hasClaim, userInfo } = useContext(UserContext);
  const isAssistantActive = hasClaim(Claims.AiAssistantChat);
  const [addFeedbackServer] = useAssistantQaFeedbackMutation();
  const [askForMoreServer] = useAssistantAskForMoreMutation();
  const [deleteConversationServer] = useAssistantDeleteConversationMutation();

  const defaultConversation: Conversation = {
    creation: "",
    id: uuidv4(),
    qas: [],
    userId: userInfo?.id || "",
  };

  const getConversationHistory = async (
    page: number,
    filters?: AssistantFilters,
    force?: boolean,
    limit?: number,
  ): Promise<boolean> => {
    setCurrentConversationHistoryPage(page);
    const newConversationPages =
      latestCheck < new Date().getTime() - 20 * 60 * 1000 || force || filters?.searchTerm !== searchedTerm
        ? []
        : [...conversationPages];

    if (typeof newConversationPages[page] === "undefined") {
      setLoading(true);
      const result = await fetchHistory({
        limit: typeof limit === "undefined" ? defaultLimit : limit,
        currentPage: page,
        filters: {
          searchTerm: filters?.searchTerm,
        },
      });
      if (result && result.data) {
        const convPage = result.data.assistantGetUserConversationHistory?.data
          ? ([...result.data.assistantGetUserConversationHistory.data] as Conversation[])
          : [];

        newConversationPages[page] = convPage;
        setConversationPages(newConversationPages);
        setPageCount(result.data.assistantGetUserConversationHistory?.pageCount || 0);
        setSearchedTerm(filters?.searchTerm || "");
        setLoading(false);
        if (page === 0) setLatestCheck(new Date().getTime());
        return true;
      }
      return false;
    }
    return false;
  };

  const resetHistory = async (): Promise<void> => {
    await getConversationHistory(0, undefined, true);
    setLatestReset(new Date().getTime());
  };

  const addQuestion = (question: string, questionId: string): void => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __typename, ...newConv } = currentConversation || defaultConversation;
    newConv.qas = newConv.qas?.concat({ question, date: getMongoNow(), id: questionId });
    setCurrentConversation(newConv);
  };

  const askQuestion = async (question: string): Promise<boolean> => {
    addQuestion(question, uuidv4());
    const result = await askQuestionServer({ variables: { question, conversationId: currentConversation?.id } });
    if (result && result.data) {
      const newConversation = result.data.assistantAskQuestion as Conversation;
      setCurrentConversation(newConversation);
      resetHistory();
      return true;
    }
    return false;
  };

  const askForMore = async (): Promise<boolean> => {
    const result = await askForMoreServer({ variables: { conversationId: currentConversation?.id || "" } });
    if (result && result.data) {
      const newConversation = result.data.assistantAskForMore as Conversation;
      setCurrentConversation(newConversation);
      resetHistory();
      return true;
    }
    return false;
  };

  const addFeedback = async (
    conversationId: string,
    qaId: string,
    userFeedback?: FeedbackConversationQa,
  ): Promise<boolean> => {
    try {
      const result = await addFeedbackServer({ variables: { conversationId, qaId, userFeedback } });
      if (result && result.data) {
        const newConversation = result.data.assistantQAFeedback as Conversation;
        setCurrentConversation(newConversation);
        resetHistory();
        return true;
      }
      return false;
    } catch (e) {
      log("couldn't add feedback", Lvl.ERROR, e);
      return false;
    }
  };

  const deleteConversation = async (conversationId: string): Promise<boolean> => {
    try {
      const result = await deleteConversationServer({ variables: { conversationId } });
      if (result && result.data) {
        const success = result.data.assistantDeleteConversation as boolean;
        if (success) {
          if (currentConversation?.id === conversationId) setCurrentConversation(undefined);
          await resetHistory();
        }
        return success;
      }
      return false;
    } catch (e) {
      log("couldn't add feedback", Lvl.ERROR, e);
      return false;
    }
  };

  return (
    <AssistantContext.Provider
      value={{
        conversationPages,
        currentConversation,
        openGreeny,
        setCurrentConversation,
        getConversationHistory,
        askQuestion,
        askForMore,
        deleteConversation,
        setOpenGreeny,
        isAssistantActive,
        addFeedback,
        openGreenySubscription,
        setOpenGreenySubscription,
        pageCount,
        latestReset,
        currentConversationHistoryPage,
        loading,
      }}>
      {children}
    </AssistantContext.Provider>
  );
};
