import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../data/contexts/UserContext";
import { dateConvertToString, userToUserUpdate } from "../data/dataConvertors";
import { Gender, Maybe, UserUpdate } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import GdButton from "../utils/GdButton";

import PhoneTextField from "../common/PhoneTextField";
import SiretInput from "../common/SiretInput";
import { AssistantContext } from "../data/contexts/AssistantContext";
import GdIframeModal from "../utils/GdIframeModal";
import "./Profile.css";

const Profile: FC = () => {
  const { userInfo, updateUser, updateError, withdrawAssistantSubscription } = useContext(UserContext);
  const { isAssistantActive } = useContext(AssistantContext);
  const [newUser, setNewUser] = useState(userInfo ? userToUserUpdate(userInfo) : { id: "none" });
  const [loading, setLoading] = useState(false);
  const [charterOpen, setCharterOpen] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);
  const { t } = useTranslation(["user", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (loading) {
      enqueueSnackbar(t("confirmUpdate"), { variant: "success" });
      setLoading(false);
    }
    if (userInfo?.phone !== newUser.phone) {
      setNewUser(userInfo ? userToUserUpdate(userInfo) : { id: "none" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleUpdateUser = (): void => {
    setLoading(true);
    updateUser(newUser as UserUpdate);
  };

  const fnChange =
    (userProp: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void =>
      setNewUser({ ...newUser, [userProp]: typeof e === "string" ? e : e.target.value });
  const fnChangeCompany =
    (companyProp: string) =>
    (e: ChangeEvent<HTMLInputElement>): void =>
      setNewUser({
        ...newUser,
        company: newUser.company
          ? { ...newUser.company, [companyProp]: e.target.value }
          : { siret: "", [companyProp]: e.target.value },
      });
  const genderChange = (e: SelectChangeEvent<Maybe<Gender> | undefined>): void =>
    setNewUser({ ...newUser, gender: e.target.value as Gender });

  const hasContract = (userInfo?.assistantSubscription?.signatureDate?.length || 0) > 0 && isAssistantActive;
  const downloadContract = (): void => {
    const contractUrl = userInfo?.assistantSubscription?.contract?.details?.completedFilesUrl?.[0] || "";
    window.open(`${contractUrl}?t=${new Date().getTime()}`);
  };
  const canWithdraw = Boolean(userInfo?.assistantSubscription?.canWithdraw);
  const withdraw = async (): Promise<void> => {
    setWithdrawing(true);
    const result = await withdrawAssistantSubscription();
    if (!result) enqueueSnackbar(t("withdrawError"), { variant: "error" });
    setWithdrawing(false);
  };

  return (
    <BaseCard>
      {userInfo?.userCharter ? (
        <GdButton label={t("viewCharter")} onClick={() => setCharterOpen(true)} className="profile-charter-button" />
      ) : undefined}
      {hasContract ? (
        <>
          <div className="profile-section first">
            <Typography variant="h5" className="profile-section-title" color="primary">
              {t("mySubscription")}
            </Typography>
            <div className="profile-form-root">
              <div className="profile-form-row space-between">
                <div className="column small-margin-top">
                  <Typography className="margin-bottom">
                    {t("subscription.date")} {dateConvertToString(userInfo?.assistantSubscription?.signatureDate)}
                  </Typography>
                  <Typography>
                    {t("subscription.dealNumber")} {userInfo?.assistantSubscription?.dealNumber}
                  </Typography>
                </div>
                <div className="column">
                  <GdButton
                    label={t("subscription.downloadContract")}
                    onClick={downloadContract}
                    className={canWithdraw ? "margin-bottom" : ""}
                  />
                  {canWithdraw ? (
                    <GdButton
                      label={t("subscription.withdraw")}
                      color="warning"
                      onClick={withdraw}
                      isLoading={withdrawing}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <Divider className="profile-divider" />
        </>
      ) : undefined}
      <div className={`profile-section${!hasContract ? " first" : ""}`}>
        <Typography variant="h5" className="profile-section-title" color="primary">
          {t("myInfos")}
        </Typography>
        <div className="profile-form-root">
          <div className="profile-form-row">
            <FormControl style={{ width: 100 }}>
              <InputLabel id="civ-select-label">{t("civility")}</InputLabel>
              <Select
                labelId="civ-select-label"
                label={t("civility")}
                variant="outlined"
                value={newUser.gender}
                onChange={genderChange}
                style={{ width: 100 }}>
                <MenuItem value={undefined}>
                  <em>{t("civ.select")}</em>
                </MenuItem>
                <MenuItem value={Gender.M}>{t("civ.mr")}</MenuItem>
                <MenuItem value={Gender.F}>{t("civ.mrs")}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label={t("lastName")}
              variant="outlined"
              value={newUser.lastName}
              onChange={fnChange("lastName")}
              className="not-only-textfield"
            />
            <TextField
              label={t("firstName")}
              variant="outlined"
              value={newUser.firstName}
              onChange={fnChange("firstName")}
              className="not-only-textfield"
            />
          </div>
          <div className="profile-form-row">
            <PhoneTextField label={t("phone")} value={newUser.phone || ""} onChange={fnChange("phone")} />
            <TextField
              label={t("email")}
              variant="outlined"
              value={newUser.email}
              onChange={fnChange("email")}
              className="not-only-textfield"
            />
          </div>
          <div className="profile-form-row">
            <TextField
              label={t("role")}
              variant="outlined"
              value={newUser.role}
              onChange={fnChange("role")}
              fullWidth
            />
          </div>
        </div>
      </div>
      <Divider className="profile-divider" />
      <div className="profile-section">
        <Typography variant="h5" className="profile-section-title" color="primary">
          {t("myCompany")}
        </Typography>
        <div className="profile-form-root">
          <div className="profile-form-row">
            <SiretInput
              siret={newUser.company?.siret || ""}
              setSiret={(siret) => setNewUser({ ...newUser, company: { ...newUser.company, siret } })}
              onCompanyFetched={(company) => setNewUser({ ...newUser, company: { ...newUser.company, ...company } })}
            />
            <TextField
              label={t("companyName")}
              variant="outlined"
              value={newUser.company?.name}
              onChange={fnChangeCompany("name")}
              className="not-only-textfield"
            />
          </div>
          <div className="profile-form-row">
            <TextField
              label={t("address")}
              variant="outlined"
              value={newUser.company?.address}
              onChange={fnChangeCompany("address")}
              fullWidth
            />
          </div>
          <div className="profile-form-row">
            <TextField
              label={t("zipCode")}
              variant="outlined"
              value={newUser.company?.zipCode}
              onChange={fnChangeCompany("zipCode")}
            />
            <TextField
              label={t("city")}
              variant="outlined"
              value={newUser.company?.city}
              onChange={fnChangeCompany("city")}
              fullWidth
              className="not-only-textfield"
            />
          </div>
        </div>
      </div>
      <div className="profile-button-container">
        <GdButton label={t("global:save")} onClick={handleUpdateUser} isLoading={loading} />
      </div>
      <GdIframeModal
        open={charterOpen}
        onClose={() => setCharterOpen(false)}
        iframeUrl={
          userInfo?.userCharter?.details?.completedFilesUrl
            ? userInfo?.userCharter?.details?.completedFilesUrl[0]
            : userInfo?.userCharter?.modelUrl?.replace("UserCharter", "signed_UserCharter") || ""
        }
      />
    </BaseCard>
  );
};

export default Profile;
