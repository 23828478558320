import { Modal, Paper, Typography, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OperationInput } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";

export interface MachineNamesModalProps {
  operations: OperationInput[];
  open: boolean;
  onClose: () => void;
  onSubmit: (names: OperationName[]) => void;
}

export interface OperationName {
  operationId: string;
  machineName: string;
}

const extractNames = (operations: OperationInput[]): OperationName[] => {
  const formIds = operations.map((o) => o.formId);
  const duplicatedFormIds = formIds.filter((fid) => formIds.indexOf(fid) !== formIds.lastIndexOf(fid));
  const result: OperationName[] = [];
  operations.forEach((o) => {
    if (o.machineName && duplicatedFormIds.includes(o.formId))
      result.push({ operationId: o.id, machineName: o.machineName });
  });
  return result;
};

const MachineNamesModal: FC<MachineNamesModalProps> = ({ operations, open, onClose, onSubmit }) => {
  const [names, setNames] = useState<OperationName[]>(extractNames(operations));
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setNames(extractNames(operations));
  }, [operations]);

  const onValidate = (): void => {
    const nameForm = names.map((n) => `${n.machineName}${operations.find((o) => o.id === n.operationId)?.formId}`);
    if (nameForm.findIndex((nf) => nameForm.indexOf(nf) !== nameForm.lastIndexOf(nf)) !== -1) {
      enqueueSnackbar(t("errorNamesNotUnique"), { variant: "error" });
    } else {
      onSubmit(names);
    }
  };

  const formIds = operations.map((o) => o.formId);
  const duplicatedFormIds = formIds.filter((fid) => formIds.indexOf(fid) !== formIds.lastIndexOf(fid));
  const concernedOperations = operations.filter((o) => duplicatedFormIds.indexOf(o.formId) !== -1);

  return (
    <Modal open={open} onClose={onClose} className="project-modal-root names-edit">
      <Paper
        className="project-modal-paper"
        style={{ width: 450, maxHeight: window.innerHeight > 1000 ? 750 : 650, overflowY: "auto" }}>
        <Typography>{t("machineNameExplanation")}</Typography>
        {concernedOperations.map((o) => (
          <div key={o.id} className="row space-between">
            <Typography>
              {o.id} - {o.formId}
            </Typography>
            <TextField
              variant="outlined"
              className="margin-bottom"
              autoComplete="off"
              label={t("machineName")}
              value={names.find((n) => n.operationId === o.id)?.machineName || ""}
              onChange={(e) => {
                let found = false;
                const newNames = names.map((n) => {
                  if (n.operationId === o.id) {
                    found = true;
                    return { ...n, machineName: e.target.value };
                  }
                  return n;
                });
                if (!found) newNames.push({ operationId: o.id, machineName: e.target.value });
                setNames(newNames);
              }}
            />
          </div>
        ))}
        <GdButton
          label={t("global:validate")}
          disabled={concernedOperations.length !== names.length}
          onClick={onValidate}
        />
      </Paper>
    </Modal>
  );
};

export default MachineNamesModal;
