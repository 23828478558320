import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowForward, ExpandMore, WarningAmber } from "@mui/icons-material";
import { FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AttachmentType, DocumentType, Project, ValidationStatus } from "../../data/generated/graphql";
import WarningCard from "../../utils/WarningCard";
import { formatNumber } from "../../data/forms/formsTypes";
import { dateConvertToString } from "../../data/dataConvertors";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "../components/UploadFileButton";
import { UserContext } from "../../data/contexts/UserContext";

interface SignedQuoteProps {
  project: Project;
}

const SignedQuote: FC<SignedQuoteProps> = ({ project }) => {
  const [notaExpanded, setNotaExpanded] = useState(Boolean(!project.quote));
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();
  const { removeAction } = useContext(UserContext);
  const theme = useTheme();
  const isBipartite = project?.convention?.type === DocumentType.Bipartite;
  const nota = t(isBipartite ? "quoteNotaBipartite" : "quoteNota", {
    replace: {
      propositionDate: project?.convention?.details?.completedDate
        ? dateConvertToString(project?.convention?.details?.completedDate)
        : "(***Date convention***)",
      totalCapacity: formatNumber(project.totalComputation?.capacity, 3, false),
      totalValuation: formatNumber(project.totalComputation?.valuation, 2, true),
      projectReference: project.reference || "(***Référence projet***)",
      userCompany: project.userSigner?.company?.name || "(***Installateur***)",
      obligedName: project.obligedInfos?.name || "(***Nom de l'obligé***)",
      obligedSiren: project.obligedInfos?.siren || "(***Siren de l'obligé***)",
      obligedShortName: project.obligedInfos?.shortName || "(***Nom de l'obligé***)",
    },
  });

  const uploadButton = project.isReadOnly ? undefined : (
    <UploadFileButton
      projectId={project.id}
      label={t(project.quote ? "uploadQuoteAgain" : "uploadQuote")}
      type={AttachmentType.Quote}
      onAfterUpload={() => removeAction(project.id)}
    />
  );

  const seeQuoteButton = (
    <GdButton
      label={t("seeUploadedQuote", { replace: { name: project.quote?.name || "" } })}
      className="margin-bottom"
      onClick={() => window.open(project.quote?.url ? `${project.quote?.url}?t=${new Date().getTime()}` : "")}
    />
  );
  if (project.quote?.validation?.status === ValidationStatus.Accepted) {
    return (
      <>
        <Typography variant="h6" className="text-center margin-bottom">
          {t("quoteValidated")}
        </Typography>
        {seeQuoteButton}
      </>
    );
  }
  const getQuoteControls = (): ReactNode => {
    if (project.quote && project.quote.url) {
      return (
        <>
          <Typography variant="h6" className="text-center margin-bottom text-with-returns">
            {t(
              project.quote.validation?.status === ValidationStatus.Rejected
                ? "quoteRefused"
                : "quoteValidationOngoing",
              { replace: { reason: project.quote.validation?.comment || "" } },
            )}
          </Typography>
          {project.quote.validation?.status === ValidationStatus.Rejected ? uploadButton : seeQuoteButton}
        </>
      );
    }
    return uploadButton;
  };
  return (
    <>
      <Accordion
        className="margin-bottom"
        expanded={notaExpanded}
        onChange={() => setNotaExpanded(!notaExpanded)}
        style={{ color: theme.palette.warning.main, border: `1px solid ${theme.palette.warning.main}` }}
        elevation={0}>
        <AccordionSummary expandIcon={<ExpandMore style={{ color: theme.palette.warning.main }} />}>
          <WarningAmber style={{ marginRight: 8 }} />
          <Typography>{t("addThisToQuote")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="margin-bottom" style={{ fontWeight: "bold" }}>
            {t("quote_mandatory1")}
          </Typography>
          <WarningCard label={nota} warning={false} className="margin-bottom" />
          <GdButton
            className="margin-bottom"
            label={t("copyQuoteNota")}
            color="secondary"
            onClick={() =>
              navigator.clipboard.writeText(nota).then(
                () => {
                  enqueueSnackbar(t("clientLinkIsInClipboard"), { variant: "success" });
                },
                () => {
                  enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
                },
              )
            }
          />
          <Typography>{t("quote_mandatory2")}</Typography>
          <div className="row margin-bottom">
            <ArrowForward style={{ marginRight: 8 }} />
            <Typography style={{ fontStyle: "italic" }}>{t("quote_mandatory2_arrow")}</Typography>
          </div>
          <div className="centered-content-root margin-bottom">
            <Card variant="outlined" elevation={0} style={{ width: "60%", alignSelf: "center" }}>
              <Table size="small">
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    {t("quote_ht_price")}
                  </TableCell>
                  <TableCell align="right">10 000,00 €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{t("quote_tva")}</TableCell>
                  <TableCell align="right">2 000,00 €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    {t("quote_ttc_price")}
                  </TableCell>
                  <TableCell align="right">12 000,00 €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                    {t("quote_cee_amount")}
                  </TableCell>
                  <TableCell align="right">4 000,00 €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid rgba(224, 224, 224, 1)", borderBottomWidth: "0px" }}>
                    {t("quote_rest")}
                  </TableCell>
                  <TableCell align="right" style={{ borderBottomWidth: "0px" }}>
                    8 000,00 €
                  </TableCell>
                </TableRow>
              </Table>
            </Card>
          </div>
          <Typography className="margin-bottom">
            {t("quote_mandatory3_1")}
            <u>{t("quote_mandatory3_2")}</u>
            {t("quote_mandatory3_3")}
            <u>{t("quote_mandatory3_4")}</u>
          </Typography>
          <Typography>
            {t("quote_mandatory4_1")}
            <u>{t("quote_mandatory4_2")}</u>
            {t("quote_mandatory4_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{getQuoteControls()}</div>
    </>
  );
};

export default SignedQuote;
