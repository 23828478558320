import { getMetDomTom } from "../dataConvertors";
import {
  BonusDetails,
  Computation,
  DataField,
  DataFieldInput,
  Maybe,
  Operation,
  Project,
  Sector,
} from "../generated/graphql";
import { JustificationType } from "../types/ProjectTypes";
import agriForms, { activeForms as agriActiveForms } from "./data/agriForms";
import barForms, { activeForms as barActiveForms } from "./data/barForms";
import batForms, { activeForms as batActiveForms } from "./data/batForms";
import cdpForms from "./data/cdpForms";
import indForms, { activeForms as indActiveForms } from "./data/indForms";
import resForms, { activeForms as resActiveForms } from "./data/resForms";
import traForms, { activeForms as traActiveForms } from "./data/traForms";
import { Form, formatNumber, formatValue, FormField, getDisplayableFields } from "./formsTypes";

const defaultComputationDate = "2023-09-30T01:00:00.000Z";
export const isNotYetApplicableForm = (form?: Form): boolean => {
  const today = (): string => new Date().toISOString();
  return typeof form?.startDate !== "undefined" && form?.startDate > today() && typeof form?.history !== "undefined";
};

export const getFormsForSector = (sector?: Maybe<Sector>, zipCode?: string): Form[] => {
  const today = new Date().toISOString();
  const stillAliveFilter = (f: Form): boolean => !f.endDate || f.endDate < today;
  const metDomTom = zipCode ? getMetDomTom(zipCode) : 0;
  const metDomTomFormsFilter = (f: Form): boolean => !f.metDomTom || f.metDomTom === metDomTom;
  switch (sector) {
    case Sector.Ind:
      return indForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Agri:
      return agriForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bat:
      return batForms
        .filter(metDomTomFormsFilter)
        .concat(
          indForms.filter(
            (f) =>
              f.id === "IND-BA-112" ||
              f.id === "IND-UT-113" ||
              f.id === "IND-UT-115" ||
              f.id === "IND-UT-116" ||
              f.id === "IND-UT-117" ||
              f.id === "IND-UT-136",
          ),
        );
    case Sector.Tra:
      return traForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Res:
      return resForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bar:
      return barForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    default:
      return [];
  }
};

export const getActiveFormsId = (sector?: Maybe<Sector>): string[] => {
  switch (sector) {
    case Sector.Ind:
      return indActiveForms;
    case Sector.Agri:
      return agriActiveForms;
    case Sector.Bat:
      return batActiveForms;
    case Sector.Tra:
      return traActiveForms;
    case Sector.Res:
      return resActiveForms;
    case Sector.Bar:
      return barActiveForms;
    default:
      return [];
  }
};

export const getApplicableForm = (
  operation: Operation,
  sector: Sector,
  zipCode: string,
  originalOperation?: Operation,
): Form | undefined => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  const computationDate =
    (typeof originalOperation !== "undefined"
      ? originalOperation?.computation?.computationDate
      : operation.computation?.computationDate) || defaultComputationDate;

  const isOperationOlderThanForm = typeof form?.startDate !== "undefined" && form.startDate > computationDate;
  if (isNotYetApplicableForm(form) || isOperationOlderThanForm) {
    const history = form?.history || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const f of history) {
      if (!f.startDate || f.startDate < computationDate) {
        return f;
      }
    }
  }
  return form;
};

export const extractOperationsConfig = (
  sector: Maybe<Sector> | undefined,
  zipCode: string,
  operations: Operation[],
  prefix: string,
  success: () => void,
  failure: () => void,
  capaValue?: boolean,
  totalComputation?: Maybe<Computation>,
  cpePrefix?: string,
  bonusPrefix?: string,
): void => {
  const isCpeDefined = !Number.isNaN(parseFloat(totalComputation?.cpeBonus || ""));
  const lines: string[] = [];
  operations.forEach((o) => {
    // const form = getFormsForSector(sector, zipCode).find((f) => f.id === o.formId);
    const form = getApplicableForm(o, sector || Sector.Ind, zipCode);
    const fields = getDisplayableFields(form?.fields || [], o.data);
    lines.push(
      `${prefix} ${o.id} - ${o.formId}${o.machineName ? ` (${o.machineName})` : ""}${
        capaValue
          ? ` : ${formatValue(o.computation?.capacity, true, 3, true)} MWhca${
              o.computation?.valuation === "0" ? "" : ` / ${formatValue(o.computation?.valuation, false, 2, true)} €`
            }`
          : ""
      }`,
    );
    fields.forEach((ff) => {
      lines.push(
        `- ${
          ff.titles
            ? ff.titles.find((ft) => ft.fieldValue === o.data?.find((d) => d?.fieldId === ft.fieldId)?.value)?.title
            : ff.title
        } : ${
          ff.type === "number" || ff.type === "range"
            ? formatValue(o.data.find((d) => d?.fieldId === ff.id)?.value, true, 3)
            : ff.choices?.find((fc) => fc.value === o.data.find((d) => d?.fieldId === ff.id)?.value)?.name
        }`,
      );
    });
    if (o.bonusDetails && o.bonusDetails?.length > 0) {
      lines.push(`${o.bonusDetails?.length} ${bonusPrefix}`);
    }
  });
  if (capaValue && totalComputation && cpePrefix && totalComputation?.cpeBonus && isCpeDefined) {
    lines.push(
      `${cpePrefix} : ${formatNumber(
        (parseFloat(totalComputation.capacity) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        3,
        true,
      )} MWhca / ${formatNumber(
        (parseFloat(totalComputation.valuation) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        2,
        true,
      )} €`,
    );
  }
  navigator.clipboard.writeText(lines.join("\n")).then(success, failure);
};

export const isZniScoped = (zipCode: string): boolean => {
  return getMetDomTom(zipCode) === 2 || zipCode.substring(0, 2) === "20";
};

export const getCdpFields = (formId: string): FormField[] => cdpForms.find((c) => c.id === formId)?.fields || [];

export const calculateCdpBonus = (cdpData: DataField[], formId: string, data: DataField[]): BonusDetails => {
  switch (formId) {
    case "BAT-TH-127": {
      const s = parseFloat(data.find((d) => d.fieldId === "area")?.value || "");
      if (s <= 7500) {
        return { justification: JustificationType.cdp, addition: "11000" };
      }
      return { justification: JustificationType.cdp, addition: (1.07 * s + 3000).toString() };
    }
    default:
      return { justification: JustificationType.cdp, multiplicator: cdpData[cdpData.length - 1].value };
  }
};

export const projectIsCpeScoped = (project?: Project): boolean =>
  Boolean(project?.details?.cpeDuration && project?.details?.cpePercentage);

export const replacementFieldId = "replacement";

export const calculateBonusOperation = (
  operation: Operation,
  startDate: string,
  zipCode?: string,
  isCpeScoped?: boolean,
): Operation => {
  const zniScoped = isZniScoped(zipCode || "");
  const bonusDetails: BonusDetails[] = [];
  if (zniScoped) {
    bonusDetails.push({ justification: JustificationType.zni, multiplicator: "2" });
  }
  if (isCpeScoped === true) {
    return { ...operation, bonusDetails };
  }
  if (
    startDate < "2025-12-31T22:00:00.000Z" &&
    operation.cdpData &&
    operation.cdpData.length > 0 &&
    (operation?.cdpData?.[operation.cdpData.length - 1]?.fieldId !== replacementFieldId ||
      operation?.cdpData?.find((c) => c?.fieldId === replacementFieldId)?.value === "true")
  ) {
    bonusDetails.push(
      calculateCdpBonus(operation.cdpData as DataField[], operation.formId, operation.data as DataField[]),
    );
  }
  return { ...operation, bonusDetails };
};

export const formIsPreview = (form?: Form): boolean => {
  const today = new Date().toISOString();
  const isPreview = (form?.startDate && form.startDate > today && (form.history?.length || 0) === 0) || false;
  return isPreview;
};

export const cdpFormIsComplete = (
  newOperation: Operation,
  cdpFields: FormField[],
  replacementData?: Maybe<DataFieldInput>,
): boolean => {
  if ((replacementData?.value?.length || 0) > 0) {
    if (replacementData?.value === "true") {
      if (
        getDisplayableFields(cdpFields || [], newOperation.cdpData || []).findIndex(
          (ff) => newOperation?.cdpData?.findIndex((c) => c?.fieldId === ff.id && (c?.value?.length || 0) > 0) === -1,
        ) === -1
      ) {
        return true;
      }
      return false;
    }

    if (replacementData?.value === "false") {
      return true;
    }
    return false;
  }
  return false;
};

export const isFormComplete = (
  newOperation: Operation,
  cdpFields: FormField[],
  replacementData?: Maybe<DataFieldInput>,
  selectedForm?: Form,
): boolean => {
  if (
    getDisplayableFields(selectedForm?.fields || [], newOperation.data).findIndex(
      (ff) => newOperation.data.findIndex((d) => d?.fieldId === ff.id && (d?.value?.length || 0) > 0) === -1,
    ) === -1
  ) {
    if (cdpFields.length > 0) {
      return cdpFormIsComplete(newOperation, cdpFields, replacementData);
    }
    return true;
  }
  return false;
};
