import { getFormsForSector, isNotYetApplicableForm } from "../data/forms/FormHelper";
import { Operation, ProcedureStatus, Project, Sector } from "../data/generated/graphql";

const defaultComputationDate = "2023-09-30T01:00:00.000Z";

export const dontTouchComputationOnProject = (project: Project): boolean =>
  Boolean(
    project.convention &&
      project.convention.status !== ProcedureStatus.Refused &&
      project.convention.status !== ProcedureStatus.Expired &&
      project.convention.status !== ProcedureStatus.Asked,
  );

export const isLatestForm = (operation: Operation, sector: Sector, zipCode: string): boolean => {
  let form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  if (isNotYetApplicableForm(form)) form = form?.history?.[0];
  return Boolean(
    (!form?.startDate ||
      !operation.computation?.computationDate ||
      form?.startDate < (operation.computation?.computationDate || defaultComputationDate)) &&
      !operation.computation?.validity?.toLowerCase().includes("error"),
  );
};

export const isProjectObsolete = (project: Project): boolean => {
  if (dontTouchComputationOnProject(project)) return false;
  return (
    (project.operations || []).findIndex(
      (o) => o && !isLatestForm(o, project.details?.sector || Sector.Ind, project.client?.company?.zipCode || ""),
    ) !== -1
  );
};
