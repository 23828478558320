import { CircularProgress, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface CenteredContentProps extends PropsWithChildren {
  loading?: boolean;
  errorText?: string;
  infoText?: string;
  className?: string;
  loadingSize?: number;
}

const CenteredContent: FC<CenteredContentProps> = ({
  loading,
  errorText,
  infoText,
  children,
  className,
  loadingSize,
}) => (
  <div className={className ? `centered-content-root ${className}` : "centered-content-root"}>
    {loading ? (
      <CircularProgress size={loadingSize} />
    ) : (
      children || (
        <Typography variant="h6" style={{ whiteSpace: "pre-line", textAlign: "center" }}>
          {errorText || infoText}
        </Typography>
      )
    )}
  </div>
);

CenteredContent.defaultProps = {
  loading: false,
  errorText: undefined,
  infoText: undefined,
  className: undefined,
  loadingSize: undefined,
};

export default CenteredContent;
