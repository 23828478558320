import { createContext, FC, PropsWithChildren, useState } from "react";
import { Form, SectorForms, useFormGetAllForAllSectorsQuery, useFormGetByIdQuery } from "../generated/graphql";

export interface FormContextProps {
  formLoading: boolean;
  sectorForms: SectorForms[];
  getAllForms: () => Promise<void>;
  getFormById: (id: string) => Promise<Form>;
}

const initialContext: FormContextProps = {
  formLoading: false,
  sectorForms: [],
  getAllForms: () => Promise.resolve(),
  getFormById: () => Promise.resolve({ id: "none" }),
};

export const FormContext = createContext<FormContextProps>(initialContext);

export const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [formLoading, setFormLoading] = useState(false);
  const [sectorForms, setSectorForms] = useState<SectorForms[]>([]);
  const { refetch: fetchAll } = useFormGetAllForAllSectorsQuery({ skip: true });
  const { refetch: fetchById } = useFormGetByIdQuery({ skip: true });

  const tryGetForm = (id: string, sector: string): Form | null => {
    const form = sectorForms.find((sf) => sf.sector === sector)?.forms?.find((f) => f.id === id);
    return form && form.fields && form.fields.length > 0 ? form : null;
  };

  const getAllForms = async (): Promise<void> => {
    setFormLoading(true);
    const result = await fetchAll();
    setSectorForms(result.data.formGetAllForAllSectors || []);
    setFormLoading(false);
  };

  const getFormById = async (id: string): Promise<Form> => {
    const sector = id.split("-")[0];
    const localForm = tryGetForm(id, sector);
    if (localForm) return Promise.resolve(localForm);
    setFormLoading(true);
    const result = await fetchById({ id });
    if (!result.data.formGetById) throw new Error("Form not found");
    setSectorForms(
      sectorForms.map((sf) => {
        if (sf.sector === sector) {
          return {
            ...sf,
            forms: sf.forms?.map((f) => {
              if (f.id === id) return result.data.formGetById as Form;
              return f;
            }),
          };
        }
        return sf;
      }),
    );
    setFormLoading(false);
    return result.data.formGetById;
  };

  return (
    <FormContext.Provider
      value={{
        formLoading,
        sectorForms,
        getAllForms,
        getFormById,
      }}>
      {children}
    </FormContext.Provider>
  );
};
