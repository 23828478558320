import { Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectContext } from "../data/contexts/ProjectContext";
import { UserContext } from "../data/contexts/UserContext";
import { consolidateProjectsComputation } from "../data/dataConvertors";
import { formatNumber } from "../data/forms/formsTypes";
import ProjectsSummary from "../project/components/ProjectsSummary";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import GdButton from "../utils/GdButton";
import ValueCard from "../utils/ValueCard";
import { AssistantContext } from "../data/contexts/AssistantContext";

import "./Dashboard.css";

const Dashboard: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isAssistantActive, setOpenGreenySubscription, openGreenySubscription } = useContext(AssistantContext);
  const { projects, getMyProjects, error } = useContext(ProjectContext);
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation(["project", "aiAssistant"]);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getMyProjects().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <CenteredContent loading />;
  if (error) return <CenteredContent errorText={t("errorWhileLoading")} />;

  const createProjectClick = (): void => history.push("/projects/create");
  const viewAllProjectClick = (): void => history.push("/projects");
  const latestProjects = [...projects]
    .filter((p) => p.userId === userInfo?.id)
    .sort((p1, p2) => {
      if (!p2.latestModification || !p1.latestModification || p1.latestModification > p2.latestModification) return -1;
      return 1;
    })
    .slice(0, 3);
  const consolidated = consolidateProjectsComputation(projects.filter((p) => p.userId === userInfo?.id));

  const latestReadOnlyProjects = [...projects]
    .filter((p) => p.userId !== userInfo?.id)
    .sort((p1, p2) => {
      if (!p2.latestModification || !p1.latestModification || p1.latestModification > p2.latestModification) return -1;
      return 1;
    })
    .slice(0, 3);

  const openAssistantSubcription = (): void => setOpenGreenySubscription(!openGreenySubscription);

  const assistantCta = isAssistantActive ? null : (
    <BaseCard className="big-margin-bottom">
      <div className="row space-evenly">
        <img
          width={250}
          src="https://greendeed.blob.core.windows.net/static/greeny-logo-gradient.png"
          alt="Logo Greeny"
        />
        <div className="assistant-cta-text">
          <Typography variant="h6">{t("aiAssistant:subscription.callToActionTitle")}</Typography>
          <Typography>
            {t("aiAssistant:subscription.overlaySpeech")}
            {"\n"}
            <a href={t("aiAssistant:subscription.moreInfoLink")} target="_blank" rel="noreferrer">
              {t("aiAssistant:subscription.overlayMoreInfo")}
            </a>
          </Typography>
        </div>
        <GdButton label={t("aiAssistant:subscription.subscribe")} onClick={openAssistantSubcription} />
      </div>
    </BaseCard>
  );

  return latestProjects.length > 0 || latestReadOnlyProjects.length > 0 ? (
    <>
      <div className="dashboard-figures">
        <ValueCard title={t("totalProjects")} value={formatNumber(consolidated.nbProjects)} forDashboard />
        <ValueCard title={t("totalCapacity")} value={formatNumber(consolidated.capacity)} forDashboard />
        <ValueCard title={t("totalValuation")} value={formatNumber(consolidated.valuation)} forDashboard />
      </div>
      <div style={{ flex: 1 }}>
        {latestProjects.length > 0 ? (
          <BaseCard className="big-margin-bottom">
            <div className="row space-between big-margin-bottom">
              <div className="row">
                <Typography variant="h6">{t("dashboardProjects")}</Typography>
                <GdButton
                  className="margin-left"
                  label={t("allProjects")}
                  color="secondary"
                  onClick={viewAllProjectClick}
                />
              </div>
              <GdButton label={t("createProject")} onClick={createProjectClick} />
            </div>
            <ProjectsSummary projects={latestProjects} showLatestUpdate />
          </BaseCard>
        ) : (
          <GdButton
            label={t("createProject")}
            onClick={createProjectClick}
            className="big-margin-bottom"
            width="large"
          />
        )}
        {assistantCta}
        {latestReadOnlyProjects.length > 0 ? (
          <BaseCard>
            <div className="row space-between big-margin-bottom">
              <Typography variant="h6">{t("dashboardReadOnlyProjects")}</Typography>
            </div>
            <ProjectsSummary projects={latestReadOnlyProjects} showSignaturesStatus readOnly />
          </BaseCard>
        ) : undefined}
      </div>
    </>
  ) : (
    <CenteredContent>
      <div className="column">
        <div className="empty big-margin-bottom">
          <Typography variant="h6" className="big-margin-bottom">
            {t("dashboardNoProjectYet")}
          </Typography>
          <GdButton label={t("createProject")} onClick={createProjectClick} width="large" />
        </div>
        {assistantCta}
      </div>
    </CenteredContent>
  );
};

export default Dashboard;
