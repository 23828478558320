import { Close, ExpandMore, InsertDriveFile } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../data/contexts/UserContext";
import { Article, ArticleType } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";

interface ArticleItemProps {
  article: Article;
  attachmentTitle: string;
}

const ArticleItem: FC<ArticleItemProps> = ({ article, attachmentTitle }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant="h6">{article.title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography className="ql-editor">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: article.body }} />
      </Typography>
      {article.attachments && article.attachments.length > 0 ? (
        <div className="big-margin-top">
          <Typography style={{ fontWeight: "bold" }}>{attachmentTitle}</Typography>
          <List>
            {article.attachments.map((a) => (
              <ListItem key={a.id}>
                <ListItemAvatar>
                  <Avatar>
                    <InsertDriveFile />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <a href={a.url as string} target="_blank" rel="noreferrer" className="attachment">
                      {a.name}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      ) : undefined}
    </AccordionDetails>
  </Accordion>
);

interface ArticlesProps {
  type: ArticleType;
}

const Articles: FC<ArticlesProps> = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const { articles, getArticles } = useContext(UserContext);
  const { t } = useTranslation("global");
  const finalArticles = articles
    .filter((a) => a.type === type && a.active)
    .filter(
      (a) =>
        filter.length === 0 ||
        a.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        a.body.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
    );
  useEffect(() => {
    setLoading(true);
    (async () => {
      await getArticles();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <CenteredContent loading />
  ) : (
    <BaseCard>
      <div className="row space-between big-margin-bottom">
        <Typography variant="h6">{t(`titles./${type.toLowerCase()}`)}</Typography>
        <FormControl style={{ width: 300 }} variant="outlined">
          <InputLabel htmlFor="article-filter">{t("filterArticle")}</InputLabel>
          <OutlinedInput
            id="article-filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setFilter("")} edge="end">
                  <Close />
                </IconButton>
              </InputAdornment>
            }
            label={t("filterArticle")}
          />
        </FormControl>
      </div>
      {finalArticles.length > 0 ? (
        finalArticles.map((a) => (
          <ArticleItem
            article={a}
            key={a.id}
            attachmentTitle={t("attachmentsTwo", { count: a.attachments ? a.attachments.length : 0 })}
          />
        ))
      ) : (
        <Typography className="text-center margin-top">{t("noArticle")}</Typography>
      )}
    </BaseCard>
  );
};
export default Articles;
