import { FC, PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGa from "react-ga4";
import { log, Lvl } from "../utils/log";

const AnalyticsWrapper: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    try {
      if (!ReactGa.isInitialized) ReactGa.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
      ReactGa.send({ hitType: "pageview", page: location.pathname });
    } catch (err) {
      log("Analytics error", Lvl.WARN, err);
    }
  }, [location]);
  return <>{children}</>;
};

export default AnalyticsWrapper;
