import { Modal, Paper } from "@mui/material";
import { FC } from "react";

interface GdIframeModalProps {
  open: boolean;
  onClose: () => void;
  iframeUrl: string;
}

const GdIframeModal: FC<GdIframeModalProps> = ({ open, onClose, iframeUrl }) => (
  <Modal open={open} onClose={onClose} className="project-modal-root">
    <Paper className="project-modal-paper charter-modal">
      <iframe
        src={iframeUrl}
        width={window.innerWidth > 1200 ? "1050" : "950"}
        height={window.innerHeight > 1000 ? "650" : "550"}
        title="Document"
      />
    </Paper>
  </Modal>
);

export default GdIframeModal;
