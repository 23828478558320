import { InteractionType } from "@azure/msal-browser";
import { AuthError } from "@azure/msal-common";
import { useMsalAuthentication } from "@azure/msal-react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { log, Lvl } from "../../utils/log";
import { MSAL_DEFAULT_SCOPES } from "../authConfig";

const Authenticate: FC<{ onError: (error: AuthError) => void }> = ({ onError }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { error } = useMsalAuthentication(InteractionType.Redirect, MSAL_DEFAULT_SCOPES);

  useEffect(() => {
    if (error !== null) {
      log("Authenticate error", Lvl.ERROR, error);
      enqueueSnackbar(JSON.stringify(error), { variant: "error" });
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <CircularProgress size={24} className="anonymous-spinner" />;
};

export default Authenticate;
