import { Modal, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../data/contexts/UserContext";
import { checkAndFormatPhoneNumber, companyToCompanyInput } from "../data/dataConvertors";
import { Company, ProcedureStatus } from "../data/generated/graphql";
import "../project/components/NameClient.css";
import GdButton from "../utils/GdButton";
import { TextMaskZipCode } from "../utils/TextMasks";
import { Lvl, log } from "../utils/log";
import { YousignEventData } from "../utils/yousignIframeSDK";
import PhoneTextField from "./PhoneTextField";
import SignatureIframe from "./SignatureIframe";
import SiretInput from "./SiretInput";

interface AssistantSubscriptionModalProps {
  open: boolean;
  onClose: () => void;
}

const AssistantSubscriptionModal: FC<AssistantSubscriptionModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation(["aiAssistant", "project", "global"]);
  const { userInfo, checkAssistantSubscription } = useContext(UserContext);
  const [forceOnForm, setForceOnForm] = useState(false);
  const [checking, setChecking] = useState(false);
  const [dealNumber, setDealNumber] = useState(userInfo?.assistantSubscription?.dealNumber || "");
  const [userPhone, setUserPhone] = useState(userInfo?.phone || "");
  const [company, setCompany] = useState(
    userInfo?.assistantSubscription?.company
      ? companyToCompanyInput({ ...userInfo.assistantSubscription.company })
      : userInfo?.company
      ? companyToCompanyInput({ ...userInfo.company })
      : { siret: "", name: "", address: "", zipCode: "", city: "" },
  );
  const { enqueueSnackbar } = useSnackbar();

  const userRefused = (): void => {
    const sub = { company, dealNumber: dealNumber || userInfo?.assistantSubscription?.dealNumber || "" };
    checkAssistantSubscription(sub, userPhone).then(() => {
      enqueueSnackbar(t("subscription.refused"), { variant: "info" });
      onClose();
    });
  };

  const userSigned = (): void => {
    const sub = { company, dealNumber: dealNumber || userInfo?.assistantSubscription?.dealNumber || "" };
    checkAssistantSubscription(sub, userPhone).then(() => {
      enqueueSnackbar(t("subscription.success"), { variant: "success" });
      onClose();
    });
  };

  const onYousignError = (data: YousignEventData): void => {
    log("Yousign sent an error", Lvl.ERROR, data);
    enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
  };

  useEffect(() => {
    if (userInfo?.phone !== userPhone) {
      setUserPhone(userInfo?.phone || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const updateCompanyField =
    (fieldName: string) =>
    (newvalue: string): void => {
      setCompany({ ...company, [fieldName]: newvalue });
    };
  const handleCompanyFetched = (companyFetched: Company): void => {
    setCompany(companyToCompanyInput(companyFetched));
  };
  const trySubmit = async (): Promise<void> => {
    // Check all fields are ok
    if (
      company.siret.length === 0 ||
      company.name?.length === 0 ||
      company.address?.length === 0 ||
      company.zipCode?.length === 0 ||
      company.city?.length === 0 ||
      dealNumber.length === 0 ||
      !checkAndFormatPhoneNumber(userPhone).isCorrect
    )
      enqueueSnackbar(t("subscription.allFieldsAreMandatory"), { variant: "error" });
    else if (company.siret.length !== 14) enqueueSnackbar(t("project:siretIsIncorrect"), { variant: "error" });
    else {
      // Submit subscription
      setChecking(true);
      const result = await checkAssistantSubscription({ company, dealNumber }, userPhone, true);
      setChecking(false);
      if (!result) enqueueSnackbar(t("subscription.error"), { variant: "error" });
      else setForceOnForm(false);
    }
  };

  const signatureIsOngoing = userInfo?.assistantSubscription?.contract?.status === ProcedureStatus.Created;
  const showSignatureUrl = userInfo?.assistantSubscription?.contract?.details?.members?.[0]?.url || "";

  const form = (
    <>
      <Typography variant="h5" className="text-center">
        {t("subscription.title")}
      </Typography>
      <Typography className="text-center text-with-returns margin-top">{t("subscription.speech")}</Typography>
      <div className="project-name-client-form">
        <div className="project-name-client-form-row">
          <SiretInput
            siret={company.siret}
            setSiret={updateCompanyField("siret")}
            onCompanyFetched={handleCompanyFetched}
            fullWidth
          />
        </div>
        <div className="project-name-client-form-row">
          <TextField
            variant="outlined"
            label={t("subscription.companyInfo.name")}
            value={company.name}
            onChange={(e) => updateCompanyField("name")(e.target.value)}
            className="first-textfield"
            autoComplete="off"
            fullWidth
          />
        </div>
        <div className="project-name-client-form-row">
          <TextField
            variant="outlined"
            label={t("subscription.companyInfo.address")}
            value={company.address}
            onChange={(e) => updateCompanyField("address")(e.target.value)}
            className="first-textfield"
            autoComplete="off"
          />
        </div>
        <div className="project-name-client-form-row">
          <TextField
            variant="outlined"
            label={t("subscription.companyInfo.zipCode")}
            value={company.zipCode}
            onChange={(e) => updateCompanyField("zipCode")(e.target.value)}
            InputProps={{
              inputComponent: TextMaskZipCode as any,
            }}
            autoComplete="off"
          />
          <TextField
            variant="outlined"
            label={t("subscription.companyInfo.city")}
            value={company.city}
            onChange={(e) => updateCompanyField("city")(e.target.value)}
            className="not-only-textfield"
            autoComplete="off"
          />
        </div>
        <div className="project-name-client-form-row">
          <TextField
            variant="outlined"
            label={t("subscription.companyInfo.dealNumber")}
            value={dealNumber}
            onChange={(e) => setDealNumber(e.target.value)}
            fullWidth
            autoComplete="off"
          />
        </div>
        <div className="project-name-client-form-row">
          <PhoneTextField
            label={t("phone")}
            value={userPhone}
            onChange={(e) => setUserPhone(e)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <GdButton label={t("subscription.button")} onClick={trySubmit} isLoading={checking} />
    </>
  );

  const signature = (
    <>
      <GdButton
        label={t("subscription.modifyCoordinates")}
        className="big-margin-bottom"
        onClick={() => setForceOnForm(true)}
      />
      <SignatureIframe
        signatureUrl={showSignatureUrl}
        onDeclined={userRefused}
        onSuccess={userSigned}
        onError={onYousignError}
      />
    </>
  );

  return (
    <Modal open={open} onClose={onClose} className="project-modal-root">
      <Paper className="project-modal-paper charter-modal">
        {!signatureIsOngoing || forceOnForm ? form : signature}
      </Paper>
    </Modal>
  );
};

export default AssistantSubscriptionModal;
