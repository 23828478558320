import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { ProcedureStatus, Project } from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdButton from "../../utils/GdButton";

interface ModifyButtonProps {
  project: Project;
  projectLabelKey: string;
}

const ModifyButton: FC<ModifyButtonProps> = ({ project, projectLabelKey }) => {
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation(["project", "global"]);
  const { unlaunchProject } = useContext(ProjectContext);

  const onConfirm = async (): Promise<void> => {
    setLoading(true);
    await unlaunchProject();
    setLoading(false);
    setConfirmOpen(false);
  };

  return project.convention?.status === ProcedureStatus.Finished ? (
    <></>
  ) : (
    <>
      <GdButton label={t(projectLabelKey)} onClick={() => setConfirmOpen(true)} />
      <GdAlert
        body={t("modifyWarning")}
        title={t("global:warning")}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        okButtonClick={onConfirm}
        okButtonLoading={loading}
        cancelButton
        yesNo
      />
    </>
  );
};

export default ModifyButton;
