import { FC } from "react";
import BaseCard from "../utils/BaseCard";
import ProjectNameClient from "./components/NameClient";

const CreateProject: FC = () => {
  return (
    <BaseCard>
      <ProjectNameClient creation />
    </BaseCard>
  );
};

export default CreateProject;
