import { NavigateNext } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectContext } from "../data/contexts/ProjectContext";
import { dateConvertToString } from "../data/dataConvertors";
import { UserAction } from "../data/generated/graphql";

interface ActionItemProps {
  action: UserAction;
}

const ActionItem: FC<ActionItemProps> = ({ action }) => {
  const { projects } = useContext(ProjectContext);
  const { t } = useTranslation("project");
  const history = useHistory();

  const project = projects.find((p) => p.id === action.projectId);
  const navToProject = (): void => history.push(`/projects/${action.projectId}`);

  return (
    <ListItem key={action.projectId}>
      <ListItemButton onClick={navToProject}>
        <ListItemIcon>
          <NavigateNext />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography>
              {t(`actionText.${action.type}`)} <b>{project?.name || ""}</b>
            </Typography>
          }
          secondary={
            <Typography variant="body2">
              {t("actionSubtitle", {
                replace: {
                  latestModification: project?.latestModification
                    ? dateConvertToString(project?.latestModification)
                    : "",
                },
              })}
            </Typography>
          }
          style={{ cursor: "pointer" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ActionItem;
