/* eslint-disable no-console */
import ReactGa from "react-ga4";

export enum Lvl {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  NONE,
}

const getCircularReplacer = (): ((_: string, value: unknown) => unknown) => {
  const seen = new WeakSet();
  return (_: string, value: unknown): unknown => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    // eslint-disable-next-line consistent-return
    return value;
  };
};

const getError = (args: unknown[]): string => {
  if (args.length === 0) return "No error details";
  try {
    const err = args[0];
    if (typeof err === "string") return err;
    return JSON.stringify(err, getCircularReplacer());
  } catch (e) {
    return "Could not extract error details";
  }
};

const traceError = (msg: unknown, args: unknown[]): void => {
  try {
    if (!ReactGa.isInitialized) ReactGa.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
    ReactGa.event({
      category: "ClientError",
      action: msg as string,
      label: getError(args),
    });
  } catch (e) {
    console.error("Could not trace error into GA", e);
  }
};

export const log = (msg: unknown, lvl: Lvl = Lvl.NONE, ...args: unknown[]): void => {
  let logMethod = console.log;
  switch (lvl) {
    case Lvl.DEBUG:
      logMethod = console.debug;
      break;
    case Lvl.INFO:
      logMethod = console.info;
      break;
    case Lvl.WARN:
      logMethod = console.warn;
      break;
    case Lvl.ERROR:
      traceError(msg, args);
      logMethod = console.error;
      break;
    default:
      logMethod = console.log;
      break;
  }
  if (args.length > 0) logMethod(msg, ...args);
  else logMethod(msg);
};
