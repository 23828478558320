import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SiretInput from "../../common/SiretInput";
import { ClientContext } from "../../data/contexts/ClientsContext";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { companyToCompanyInput, signerToSignerInput } from "../../data/dataConvertors";
import { formatSiret } from "../../data/forms/formsTypes";
import { Company, Project, Signer } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import { log, Lvl } from "../../utils/log";
import { TextMaskActivity, TextMaskZipCode } from "../../utils/TextMasks";
import WarningCard from "../../utils/WarningCard";

import "./NameClient.css";

interface ProjectNameClientProps {
  creation?: boolean;
  project?: Project;
  onClose?: () => void;
}

const ProjectNameClient: FC<ProjectNameClientProps> = ({ creation, project, onClose }) => {
  const [client, setClient] = useState<Signer>({ company: { siret: "" } });
  const [clientSigner, setClientSigner] = useState<Signer | undefined>(undefined);
  const [name, setName] = useState("");
  const [siret, setSiret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { createProject, createError, updateProject, updateError, getCompanyFromSiret } = useContext(ProjectContext);
  const { clients, clientSigners } = useContext(ClientContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    if (project) {
      setClient(signerToSignerInput(project.client));
      setName(project.name);
      setSiret(project.client?.company?.siret || "");
    }
  }, [project]);

  const setCompany = (company: Company): void => {
    setClient({ company: companyToCompanyInput(company) || { siret } });
    setShowWarning(true);
  };
  const handleFetch = async (): Promise<void> => {
    setCompanyLoading(true);
    try {
      const company = await getCompanyFromSiret(siret);
      if (company) {
        setCompany(company);
      } else {
        enqueueSnackbar(t("companyNotFound"), { variant: "error" });
      }
    } catch (err) {
      log("Company fetch failed", Lvl.ERROR, err);
      enqueueSnackbar(t((err as Error).message === "Siret not found" ? "companyNotFound" : "inseeDown"), {
        variant: "error",
      });
    } finally {
      setCompanyLoading(false);
    }
  };
  const handleSiret = (inSiret: string): void => {
    setClient({ ...client, company: { ...client.company, siret: inSiret } });
    setSiret(inSiret);
  };
  const handleClientCompanyModify =
    (key: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setClient({ ...client, company: { ...client.company, [key]: e.target.value.toUpperCase() } });
    };
  const handleCreateProject = async (): Promise<void> => {
    setIsLoading(true);
    if (creation) {
      const id = uuidv4();
      const result = await createProject({ id, name, client, clientSigner });
      if (!result) {
        log("Error while creating project", Lvl.ERROR, createError);
        enqueueSnackbar(t("errorWhileCreating"), { variant: "error" });
      } else {
        history.push(`/projects/${id}`);
      }
    } else if (project) {
      const result = await updateProject({ id: project.id, name, client });
      if (!result) {
        log("Error while updating project", Lvl.ERROR, updateError);
        enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("updateSuccess"), { variant: "success" });
        setIsLoading(false);
        onClose?.();
      }
    }
  };
  return (
    <div className="project-name-root">
      <div className="project-name-client">
        <Typography variant="h6">{t("projectName")}</Typography>
        <TextField
          variant="outlined"
          label={t("projectName")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          className="project-name-siret"
          autoComplete="off"
        />
        <Typography variant="h6" className="margin-bottom">
          {t("client")}
        </Typography>
        {creation ? (
          <WarningCard projectLabelKey="noDirectCommunicationWithClient" info className="margin-bottom" />
        ) : undefined}
        {creation && clients.length > 0 ? (
          <FormControl fullWidth className="margin-bottom">
            <InputLabel id="client-selector">{t("previousClient")}</InputLabel>
            <Select
              labelId="client-selector"
              value={siret && clients.findIndex((c) => c.company.siret === siret) !== -1 ? siret : ""}
              onChange={(e) => {
                setSiret(e.target.value);
                setClient(clients.find((c) => c.company.siret === e.target.value) || { company: { siret } });
                setClientSigner(clientSigners.find((c) => c.clientSiret === e.target.value)?.clientSigner);
              }}
              variant="outlined"
              label={t("previousClient")}
              style={{ textAlign: "left" }}>
              {clients.map((c) => (
                <MenuItem key={c.company.siret} value={c.company.siret}>
                  {c.company.name} ({formatSiret(c.company.siret)})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : undefined}
        <SiretInput
          siret={siret || ""}
          setSiret={handleSiret}
          onCompanyFetched={setCompany}
          fullWidth
          className="margin-bottom"
        />
        {creation ? (
          <GdButton
            label={t("fetchClientData")}
            onClick={handleFetch}
            isLoading={companyLoading}
            disabled={siret.length !== 14}
          />
        ) : undefined}
        {showWarning ? <WarningCard projectLabelKey="addressWarning" warning className="margin-top" /> : undefined}
        <div className="project-name-client-form">
          <div className="project-name-client-form-row">
            <TextField
              variant="outlined"
              label={t("company.name")}
              value={client.company.name || ""}
              onChange={handleClientCompanyModify("name")}
              className="first-textfield"
              autoComplete="off"
            />
            <TextField
              variant="outlined"
              label={t("company.mainActivity")}
              value={client.company.mainActivityCode || ""}
              onChange={handleClientCompanyModify("mainActivityCode")}
              className="not-only-textfield-small"
              InputProps={{
                inputComponent: TextMaskActivity as any,
              }}
              autoComplete="off"
            />
          </div>
          <div className="project-name-client-form-row">
            <TextField
              variant="outlined"
              label={t("company.constructionAddress")}
              value={client.company.address || ""}
              onChange={handleClientCompanyModify("address")}
              className="first-textfield"
              autoComplete="off"
            />
          </div>
          <div className="project-name-client-form-row">
            <TextField
              variant="outlined"
              label={t("company.zipCode")}
              value={client.company.zipCode || ""}
              onChange={handleClientCompanyModify("zipCode")}
              InputProps={{
                inputComponent: TextMaskZipCode as any,
              }}
              autoComplete="off"
            />
            <TextField
              variant="outlined"
              label={t("company.city")}
              value={client.company.city || ""}
              onChange={handleClientCompanyModify("city")}
              className="not-only-textfield"
              autoComplete="off"
            />
          </div>
        </div>
        <GdButton
          label={t(creation ? "createProjectForClient" : "updateProject")}
          onClick={handleCreateProject}
          isLoading={isLoading}
          disabled={
            siret.length !== 14 ||
            name.length === 0 ||
            (client.company?.name?.length || 0) === 0 ||
            (client.company?.address?.length || 0) === 0 ||
            (client.company?.zipCode?.length || 0) !== 5 ||
            (client.company?.city?.length || 0) === 0
          }
          className="project-name-button"
        />
      </div>
    </div>
  );
};

ProjectNameClient.defaultProps = {
  creation: false,
  project: undefined,
  onClose: () => {},
};

export default ProjectNameClient;
