import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GdButton from "../../utils/GdButton";
import { Project, ProjectStatus } from "../../data/generated/graphql";
import { dateConvertToString } from "../../data/dataConvertors";
import { formatValue } from "../../data/forms/formsTypes";

interface ProjectsSummaryProps {
  projects: Project[];
  deleteProject?: (project: Project) => void;
  showCreation?: boolean;
  showLatestUpdate?: boolean;
  showSignaturesStatus?: boolean;
  duplicateProject?: (project: Project) => void;
  readOnly?: boolean;
}

const ProjectsSummary: FC<ProjectsSummaryProps> = ({
  projects,
  deleteProject,
  showCreation,
  showLatestUpdate,
  showSignaturesStatus,
  duplicateProject,
  readOnly,
}) => {
  const { t } = useTranslation("project");
  const history = useHistory();
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("summ.name")}</TableCell>
            {showCreation ? <TableCell align="center">{t("summ.creation")}</TableCell> : undefined}
            {showLatestUpdate ? <TableCell align="center">{t("summ.update")}</TableCell> : undefined}
            {showSignaturesStatus ? <TableCell align="center">{t("summ.status")}</TableCell> : undefined}
            <TableCell align="center">{t("summ.capacity")}</TableCell>
            <TableCell align="center">{t("summ.valuation")}</TableCell>
            <TableCell />
            {duplicateProject ? <TableCell /> : undefined}
            {deleteProject ? <TableCell /> : undefined}
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.id}>
              <TableCell>
                <b>{project.name}</b>
              </TableCell>
              {showCreation ? <TableCell align="center">{dateConvertToString(project.creation)}</TableCell> : undefined}
              {showLatestUpdate ? (
                <TableCell align="center">{dateConvertToString(project.latestModification)}</TableCell>
              ) : undefined}
              {showSignaturesStatus ? <TableCell align="center">{project.detailedStatus}</TableCell> : undefined}
              <TableCell align="center">{formatValue(project.totalComputation?.capacity, true)}</TableCell>
              <TableCell align="center">
                {project.totalComputation?.tooLow || project.totalComputation?.valuation === "0"
                  ? t("capacityTooLow")
                  : formatValue(project.totalComputation?.valuation)}
              </TableCell>
              <TableCell align="right">
                <GdButton
                  label={t(`summ.${project.status === ProjectStatus.Simulation && !readOnly ? "continue" : "see"}`)}
                  color="secondary"
                  onClick={() => history.push(`/projects/${project.id}`)}
                  justify="flex-end"
                />
              </TableCell>
              {duplicateProject ? (
                <TableCell align="right">
                  <GdButton
                    label={t("duplicateProject")}
                    onClick={() => duplicateProject(project)}
                    justify="flex-end"
                  />
                </TableCell>
              ) : undefined}
              {deleteProject ? (
                <TableCell align="right">
                  <GdButton
                    label={t("deleteProject")}
                    color="inherit"
                    onClick={() => deleteProject(project)}
                    justify="flex-end"
                  />
                </TableCell>
              ) : undefined}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProjectsSummary.defaultProps = {
  deleteProject: undefined,
  showCreation: false,
  showLatestUpdate: false,
  showSignaturesStatus: false,
  duplicateProject: undefined,
  readOnly: false,
};

export default ProjectsSummary;
