import { FC } from "react";
import { PictoProps } from "./greeny-picto";

const GreenyMonochrome: FC<PictoProps> = ({ width, height, fill }): JSX.Element => {
  return (
    <svg version="1.1" width={width} height={height} id="Calque_1" x="0px" y="0px" viewBox="0 0 159 53" fill={fill}>
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d="M22.31,40.59V24.16c-1.89-1.3-4.21-2.4-8.06-2.4c-5.15,0-9.63,3.22-9.63,9.63c0,5.9,3.03,9.48,7.59,9.48
				c1.97,0,3.38-0.79,4.21-2.01v2.01c0,2.16-1.14,3.46-3.34,3.46c-1.57,0-3.03-0.86-3.89-2.28l-3.93,2.4
				c2.12,3.38,5.39,4.48,8.69,4.48C18.97,48.93,22.31,45.98,22.31,40.59z M16.41,34.42c-0.59,1.1-1.49,1.65-2.71,1.65
				c-2.01,0-3.07-1.97-3.07-4.72c0-3.15,1.45-4.8,3.5-4.8c0.9,0,1.57,0.16,2.28,0.63V34.42z"
            />
            <path
              fill={fill}
              d="M31.07,29.07c0.86-1.22,2.63-1.85,4.33-1.85c0.24,0,0.47,0.04,0.71,0.04c0-0.08,0.55-5.39,0.55-5.47
				c-3.22,0-5.43,1.65-6.29,3.11V22l-5.19,0.16v18.36h5.9V29.07z"
            />
            <path
              fill={fill}
              d="M47.59,40.87c4.21,0,6.53-1.89,7.82-4.6l-4.29-2.2c-0.67,1.38-1.77,2.01-3.38,2.01c-1.85,0-3.46-0.67-3.81-2.6
				l12.11-1.61c-0.04-5.86-2.67-10.1-8.81-10.1c-5.58,0-9.32,3.74-9.32,9.55C37.92,37.64,42,40.87,47.59,40.87z M47.08,25.97
				c1.77,0,2.71,0.94,2.83,2.87l-6.05,1.02C43.85,27.5,44.91,25.97,47.08,25.97z"
            />
            <path
              fill={fill}
              d="M110.43,21.77h3.92c0.73,0,1.23,0.73,0.96,1.42c-1.62,4.04-6.15,15.66-7.19,18.08c-1.54,3.65-3.35,7.42-7.85,7.42
				c-0.58,0-1.08-0.04-1.54-0.08c-0.5-0.08-0.88-0.5-0.88-1.04v-3.23c0-0.54,0.46-1,1-1.04c1.88-0.04,2.85-1.12,3.58-3.19
				c-0.27-0.69-5.39-12.73-7.15-16.92c-0.31-0.65,0.19-1.42,0.96-1.42h3.85c0.73,0,1.38,0.46,1.62,1.15l3.54,9.89l3.58-9.89
				C109.05,22.23,109.7,21.77,110.43,21.77z"
            />
            <path
              fill={fill}
              d="M66.81,40.87c4.21,0,6.53-1.89,7.82-4.6l-4.29-2.2c-0.67,1.38-1.77,2.01-3.38,2.01c-1.85,0-3.46-0.67-3.81-2.6
				l12.11-1.61c-0.04-5.86-2.67-10.1-8.81-10.1c-5.58,0-9.32,3.74-9.32,9.55C57.14,37.64,61.23,40.87,66.81,40.87z M66.3,25.97
				c1.77,0,2.71,0.94,2.83,2.87l-6.05,1.02C63.08,27.5,64.14,25.97,66.3,25.97z"
            />
            <path
              fill={fill}
              d="M83.01,28.76c0.51-1.34,1.73-2.2,2.99-2.2c1.38,0,2.36,0.71,2.36,2.36v11.6h5.9V28.99c0-5.27-2.48-7.23-6.09-7.23
				c-2.67,0-4.8,1.3-5.86,3.15V22l-5.19,0.16v18.36h5.9V28.76z"
            />
          </g>
        </g>
        <g>
          <path
            fill={fill}
            d="M151.19,16.33c-0.1,0.01-0.24,0.06-0.42,0.15c-1.67,0.83-3.43,1.46-5.22,1.97c-0.33,0.09-0.55,0.18-0.56,0.58
			c-0.04,0.71-0.12,1.41-0.19,2.12c-0.43,4.13-1.93,7.85-4.75,10.89c-3.96,4.27-9.19,5.73-14.79,5.96
			c-1.08,0.04-2.83-0.06-3.09,0.07c-0.47,0.24-1.14,1.25-1.89,1.85c-0.02,0.02-0.02,0.06-0.04,0.15c0.72,0,1.33-0.25,1.87-0.66
			c0.4-0.3,0.77-0.31,1.22-0.12c0.03,0.01,0.06,0.02,0.09,0.04c0.04-0.03,0.07-0.05,0.11-0.08c0,0,2.61,1.24,6.19,1.97
			c0.02,0.01,0.04,0.01,0.05,0.01c1,0.21,2.08,0.37,3.2,0.45c0.12,0.01,0.23,0.02,0.34,0.02c0.11,0.01,0.22,0.01,0.34,0.02
			c0.44,0.02,0.88,0.03,1.31,0.02c0.08,0,0.16,0,0.24-0.01c0.03,0,0.05,0,0.08,0c0.13,0,0.26-0.01,0.38-0.02
			c2.45-0.12,4.96-0.72,7.19-2.16l0,0c0.08-0.05,0.16-0.1,0.24-0.16C149.6,34.97,151.91,27.92,151.19,16.33z"
          />
          <path
            fill={fill}
            d="M144.14,18.14c-0.09-4.51-1.44-9.62-4-15.67c-0.01-0.04-0.03-0.07-0.04-0.11c0,0-1.11,1.56-3.17,3.49
			c-0.2,0.18-0.39,0.36-0.59,0.54c-0.05,0.04-0.09,0.08-0.14,0.12c-0.93,0.81-2.01,1.66-3.24,2.48
			c-4.59,3.03-21.49,10.23-14.61,24.78c0,0-0.15,0.24-0.36,0.56c0,0,0,0.01,0,0.01c-0.16,0.24-0.36,0.53-0.56,0.79c0,0,0,0,0,0
			c-0.09,0.11-0.17,0.21-0.25,0.3c-0.07,0.08-0.14,0.15-0.21,0.21v5.2c0,0,0,0,0.01,0c0.07-0.03,0.51-0.2,1.1-0.5
			c1.19-0.6,3.01-1.7,3.76-3.08c0,0,13.29,1.17,19-7.51c2.19-3.33,3.28-6.88,3.32-10.93C144.14,18.6,144.14,18.37,144.14,18.14z"
          />
        </g>
      </g>
    </svg>
  );
};

export default GreenyMonochrome;
