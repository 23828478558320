import { Article } from "@mui/icons-material";
import { IconButton, Modal, Paper, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { UserContext } from "../../data/contexts/UserContext";
import { dateConvertToString } from "../../data/dataConvertors";
import { extractOperationsConfig, formIsPreview, getFormsForSector } from "../../data/forms/FormHelper";
import { formatNumber, formatValue } from "../../data/forms/formsTypes";
import OperationsViewer from "../../data/forms/OperationsViewer";
import {
  Operation,
  OperationInput,
  ProcedureStatus,
  Project,
  ProjectCancellationInput,
  ProjectStatus,
  RateStatus,
  Sector,
} from "../../data/generated/graphql";
import UserCharterModal from "../../profile/UserCharterModal";
import CenteredContent from "../../utils/CenteredContent";
import GdButton from "../../utils/GdButton";
import { log, Lvl } from "../../utils/log";
import ValueCard from "../../utils/ValueCard";
import WarningCard from "../../utils/WarningCard";
import ProjectCancelModal from "../components/ProjectCancelModal";
import { dontTouchComputationOnProject, isProjectObsolete } from "../ProjectHelper";

interface ResultViewerProps {
  project: Project;
}

const ResultViewer: FC<ResultViewerProps> = ({ project }) => {
  const [cancelOpen, setCancelOpen] = useState(false);
  const { t } = useTranslation(["project", "user"]);
  const [updating, setUpdating] = useState(false);
  const [charterOpen, setCharterOpen] = useState(false);
  const [preparingConventionPreview, setPreparingConventionPreview] = useState(false);
  const [isNotaOpen, setIsNotaOpen] = useState(false);
  const { launchProject, updateProject, updateError, prepareConventionPreview } = useContext(ProjectContext);
  const { userInfo } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  if (!project.totalComputation)
    return (
      <div className="project-forms-empty">
        <CenteredContent infoText={t("noResultYet")} />
      </div>
    );

  const checkUserSigned = (userJustSigned?: boolean): boolean => {
    // Check if user signed user charter, if not ask him to verify his firstName, lastName, role, email and phone
    if (userJustSigned || userInfo?.userCharter?.status === ProcedureStatus.Finished) {
      return true;
    }
    setCharterOpen(true);
    return false;
  };
  const tryLaunchProject = async (userJustSigned?: boolean): Promise<void> => {
    // Check if user signed user charter, if not ask him to verify his firstName, lastName, role, email and phone
    if (checkUserSigned(userJustSigned)) {
      // User has signed User Charter
      setUpdating(true);
      const result = await launchProject({
        id: project.id || "",
      });
      if (!result) {
        enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
      }
      history.push(`/projects/${project.id || ""}/convention`);
    }
  };
  const closeCharter = (): void => setCharterOpen(false);
  const openCancelProject = (): void => setCancelOpen(true);
  const closeCancelProject = (): void => setCancelOpen(false);
  const cancelProject = async (cancellation: ProjectCancellationInput): Promise<void> => {
    setUpdating(true);
    const result = await updateProject({
      id: project.id || "",
      cancellation,
    });
    if (!result) {
      log("Error while updating project", Lvl.ERROR, updateError);
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    }
    history.replace("/");
  };
  const success = (): void => {
    enqueueSnackbar(t("operationsConfigIsCopied"), { variant: "success" });
  };
  const failure = (): void => {
    enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
  };
  if (updating) return <CenteredContent loading />;
  const isRateTemporary = project.totalComputation?.rate?.status === RateStatus.Temporary;
  const additionalLines = [];
  if (
    project.totalComputation.rate &&
    project.totalComputation.rate?.value !== "-1" &&
    project.totalComputation.rate?.value !== "0"
  )
    additionalLines.push(
      t(isRateTemporary ? "rateIsTemporary" : "projectValidity", {
        replace: { valuation: formatNumber(project.totalComputation.rate?.value || undefined, 2, true) },
      }),
    );

  const canCancel = !project.convention || project.convention?.status !== ProcedureStatus.Finished;
  const prepareConventionPreviewForProject = async (): Promise<void> => {
    setPreparingConventionPreview(true);
    const result = await prepareConventionPreview(project.id);
    if (!result) {
      log("Error while preparing convention preview for project", Lvl.ERROR, updateError);
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    }
    setPreparingConventionPreview(false);
  };
  const nota = t("quoteNota", {
    replace: {
      propositionDate: project?.convention?.details?.completedDate
        ? dateConvertToString(project?.convention?.details?.completedDate)
        : "(***Date convention***)",
      totalCapacity: formatNumber(project.totalComputation?.capacity, 3, false),
      totalValuation: formatNumber(project.totalComputation?.valuation, 2, true),
      projectReference: project.reference || "(***Référence projet***)",
      userCompany: project.userSigner?.company?.name || "(***Installateur***)",
      obligedName: project.obligedInfos?.name || "(***Nom de l'obligé***)",
      obligedSiren: project.obligedInfos?.siren || "(***Siren de l'obligé***)",
      obligedShortName: project.obligedInfos?.shortName || "(***Nom de l'obligé***)",
    },
  });
  const dontTouchOperations = dontTouchComputationOnProject(project);
  const isObsolete = isProjectObsolete(project);
  const oneOperationIsPreview =
    project.operations?.findIndex(
      (o) => formIsPreview(getFormsForSector(project.details?.sector).find((f) => f.id === o?.formId)) === true,
    ) !== -1;
  return (
    <div>
      <div className="row space-evenly margin-bottom">
        <ValueCard
          title={t("projectCapacity")}
          value={formatValue(project.totalComputation?.capacity, true, 3, false)}
        />
        <ValueCard
          title={t("projectValuation")}
          value={
            project.totalComputation?.tooLow
              ? t("capacityTooLow")
              : project.totalComputation?.valuation === "0"
              ? t("valuationOnDemand")
              : formatValue(project.totalComputation?.valuation, false, 2, true)
          }
        />
      </div>
      <div className="margin-bottom">
        <WarningCard projectLabelKey="projectConditions" warning={false} additionalLines={additionalLines} />
      </div>
      {!oneOperationIsPreview ||
      (!project.totalComputation.tooLow && !project.totalComputation.tooHigh && !isObsolete) ? (
        project.status === ProjectStatus.Simulation && !isRateTemporary && !project.isReadOnly ? (
          <GdButton className="margin-bottom" label={t("launchProject")} onClick={() => tryLaunchProject()} fullWidth />
        ) : undefined
      ) : (
        <WarningCard
          projectLabelKey={
            oneOperationIsPreview
              ? "oneOperationIsPreview"
              : isObsolete
              ? "isObsolete"
              : project.totalComputation.tooLow
              ? "resultTooLow"
              : "resultTooHigh"
          }
          info={!isObsolete}
          className="margin-bottom"
        />
      )}
      {canCancel && !project.isReadOnly ? (
        <GdButton
          className="margin-bottom"
          label={t("cancelProject")}
          onClick={openCancelProject}
          fullWidth
          color="secondary"
        />
      ) : undefined}
      <div className="row space-between">
        <Typography variant="h6" className="margin-bottom">
          {t("operationsDetails")}
        </Typography>
        <Tooltip title={t("getOperationsConfigWithCapaValue") as string} className="margin-bottom">
          <IconButton
            onClick={() =>
              extractOperationsConfig(
                project.details?.sector,
                project.client?.company?.zipCode || "",
                project.operations as OperationInput[],
                t("operation"),
                success,
                failure,
                true,
                project.totalComputation,
                t("cpeBonification", { replace: { cpeBonus: project.totalComputation?.cpeBonus } }),
                t("bonificationPrefix"),
              )
            }
            color="primary"
            size="large">
            <Article />
          </IconButton>
        </Tooltip>
      </div>
      <OperationsViewer
        sector={project.details?.sector as Sector}
        zipCode={project.client?.company?.zipCode || ""}
        operations={(project.operations as Operation[]) || []}
        dontTouchOperations={dontTouchOperations}
      />

      {project.convention?.status === ProcedureStatus.Finished ||
      project.isReadOnly ? undefined : project.conventionPreviewUrl ? (
        <div className="big-margin-top row space-between">
          <GdButton
            className="flex1"
            label={t("refreshConventionPreview")}
            onClick={prepareConventionPreviewForProject}
            color="inherit"
            fullWidth
            isLoading={preparingConventionPreview}
          />
          <div style={{ width: 16 }} />
          <GdButton
            className="flex1"
            label={t("seeConventionPreview")}
            onClick={() => window.open(`${project.conventionPreviewUrl}?t=${new Date().getTime()}`)}
            color="inherit"
            fullWidth
          />
        </div>
      ) : (
        <GdButton
          className="big-margin-top"
          label={t("getConventionPreview")}
          onClick={prepareConventionPreviewForProject}
          color="inherit"
          fullWidth
          isLoading={preparingConventionPreview}
        />
      )}
      {project.convention?.status === ProcedureStatus.Finished ? undefined : (
        <GdButton
          className={project.isReadOnly ? "big-margin-top" : "margin-top"}
          label={t("previewNota")}
          onClick={() => setIsNotaOpen(true)}
          color="inherit"
          fullWidth
        />
      )}
      <ProjectCancelModal open={cancelOpen} onClose={closeCancelProject} onCancel={cancelProject} />
      <UserCharterModal
        open={charterOpen}
        onClose={closeCharter}
        onUserSigned={(userJustSigned?: boolean) => tryLaunchProject(userJustSigned)}
      />
      <Modal open={isNotaOpen} onClose={() => setIsNotaOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper" style={{ maxWidth: 750 }}>
          <Typography variant="h6" className="margin-bottom">
            {t("addThisToQuote")}
          </Typography>
          <WarningCard label={nota} warning={false} className="margin-bottom" />
          <GdButton
            className="margin-bottom"
            label={t("copyQuoteNota")}
            color="secondary"
            onClick={() =>
              navigator.clipboard.writeText(nota).then(
                () => {
                  enqueueSnackbar(t("clientLinkIsInClipboard"), { variant: "success" });
                },
                () => {
                  enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
                },
              )
            }
          />
          <WarningCard projectLabelKey={t("notaIsMandatory")} className="margin-bottom" />
        </Paper>
      </Modal>
    </div>
  );
};

export default ResultViewer;
