import { PlaylistAdd, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Button, CircularProgress, SvgIconTypeMap, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AssistantContext } from "../data/contexts/AssistantContext";
import { FeedbackConversationQa } from "../data/generated/graphql";

const tellMeMore = "tellMeMore";

interface AssistantFeedbackProps {
  conversationId: string;
  qaId: string;
  feedback: FeedbackConversationQa;
  showTellMeMore: boolean;
}

const AssistantFeedback: FC<AssistantFeedbackProps> = ({ conversationId, qaId, feedback, showTellMeMore }) => {
  const { t } = useTranslation("aiAssistant");
  const { addFeedback, askForMore } = useContext(AssistantContext);
  const [isPosting, setIsPosting] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeFeedback = async (answerFeedback: FeedbackConversationQa): Promise<void> => {
    setIsPosting(answerFeedback);
    await addFeedback(conversationId, qaId, answerFeedback);
    enqueueSnackbar(t("feedback.thanks"), { variant: "success" });
    setIsPosting("");
  };
  const handleTellMeMore = async (): Promise<void> => {
    setIsPosting(tellMeMore);
    const result = await askForMore();
    if (!result) enqueueSnackbar(t("error"), { variant: "error" });
    setIsPosting("");
  };
  const getFeedbackButton = (
    type: FeedbackConversationQa | "tellMeMore",
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    color: "primary" | "error" | "info",
  ): ReactNode =>
    isPosting === type ? (
      <CircularProgress size={20} style={{ margin: "0 8px" }} />
    ) : (
      <Tooltip title={t(`feedback.${type}`)} arrow placement="top" sx={{ zIndex: 42 }}>
        <Button
          sx={{ minWidth: "unset", paddingTop: 0, paddingBottom: 0 }}
          onClick={() => (type === "tellMeMore" ? handleTellMeMore() : handleChangeFeedback(type))}
          disabled={isPosting.length > 0}>
          <Icon
            fontSize="small"
            color={color}
            sx={{ opacity: type === "tellMeMore" || feedback === type ? "1" : ".5" }}
          />
        </Button>
      </Tooltip>
    );
  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {showTellMeMore ? getFeedbackButton(tellMeMore, PlaylistAdd, "info") : undefined}
      {getFeedbackButton(FeedbackConversationQa.Good, ThumbUp, "primary")}
      {getFeedbackButton(FeedbackConversationQa.Bad, ThumbDown, "error")}
    </div>
  );
};

export default AssistantFeedback;
