import { MenuItem, Modal, Paper, Select, TextField, Typography, FormControl, InputLabel } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToDataDate } from "../../data/dataConvertors";
import { ProjectCancellationInput } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import WarningCard from "../../utils/WarningCard";

interface ProjectCancelModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: (cancellation: ProjectCancellationInput) => void;
}

const ProjectCancelModal: FC<ProjectCancelModalProps> = ({ open, onClose, onCancel }) => {
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const { t } = useTranslation(["project", "global"]);

  const close = (): void => {
    setReason("");
    setDetails("");
    onClose();
  };
  const cancel = (): void => {
    onCancel({ details, reason, date: dateConvertToDataDate(new Date()) });
    close();
  };
  return (
    <Modal open={open} onClose={close} className="project-modal-root">
      <Paper className="project-modal-paper cancel-modal">
        <Typography variant="h6" className="margin-bottom">
          {t("cancelTitle")}
          <br />
          {t("cancelSubtitle")}
        </Typography>
        <WarningCard projectLabelKey="cancelWarning" textInTitle className="margin-bottom" />
        <FormControl className="margin-bottom">
          <InputLabel id="reason-select-label">{t("cancelReason")}</InputLabel>
          <Select
            labelId="reason-select-label"
            variant="outlined"
            label={t("cancelReason")}
            value={reason}
            onChange={(e) => setReason(e.target.value)}>
            <MenuItem value={t("reasons.informative")}>{t("reasons.informative")}</MenuItem>
            <MenuItem value={t("reasons.later")}>{t("reasons.later")}</MenuItem>
            <MenuItem value={t("reasons.help")}>{t("reasons.help")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="margin-bottom"
          variant="outlined"
          label={t("cancelDetails")}
          multiline
          rows={5}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <div className="row space-evenly">
          <GdButton color="secondary" label={t("cancelCancel")} onClick={close} />
          <GdButton
            label={t("cancelValidate")}
            onClick={cancel}
            disabled={details.length === 0 || reason.length === 0}
          />
        </div>
      </Paper>
    </Modal>
  );
};

export default ProjectCancelModal;
