import { Add, Visibility } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getFormsForSector } from "../../data/forms/FormHelper";
import { Form, getAttachments } from "../../data/forms/formsTypes";
import { Attachment, DataFieldInput, Maybe, Operation, Project, ValidationStatus } from "../../data/generated/graphql";
import Theme from "../../Theme";
import WarningCard from "../../utils/WarningCard";
import AddAttachmentModal from "../components/AddAttachmentModal";

interface AttachmentsProps {
  project: Project;
}

const Attachments: FC<AttachmentsProps> = ({ project }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("project");
  const forms = getFormsForSector(project.details?.sector, project.client?.company?.zipCode || "");
  const attSent: string[] = [];
  project.attachments?.forEach((a) => {
    if (!a.validation || a.validation.status !== ValidationStatus.Rejected)
      a.concerns?.forEach((c) => (attSent.indexOf(c) === -1 ? attSent.push(c) : undefined));
  });
  const allHaveBeenValidated =
    project.attachments?.findIndex((a) => !a.validation || !a.validation.status) === -1 || false;
  const getOpName = (o?: Maybe<Operation>): string =>
    o ? `${t("operation")} ${o?.id} - ${o?.formId}${o?.machineName ? ` (${o.machineName})` : ""}` : "";
  const attNeeded = attSent.indexOf("invoice") === -1 ? [{ primary: t("invoice"), secondary: "" }] : [];
  project.operations?.forEach((o) => {
    const secondaries: string[] = [];
    if (attSent.indexOf(`${o?.id}_p`) === -1) secondaries.push(t("doneProof"));
    const form = forms.find((f) => f.id === o?.formId);
    const attachments = form ? getAttachments(form as Form, o?.data as DataFieldInput[]) : [];
    attachments.forEach((a, i) => {
      if (attSent.indexOf(`${o?.id}_${i}`) === -1) secondaries.push(a);
    });
    if (secondaries.length > 0)
      attNeeded.push({
        primary: getOpName(o),
        secondary: secondaries.join(" + "),
      });
  });
  const getConcerns = (a: Attachment): ReactNode => {
    const result: {
      primary: string;
      secondary: string;
    }[] = [];
    a.concerns?.forEach((c) => {
      if (["invoice", "other"].includes(c)) result.push({ primary: t(c), secondary: "" });
      else {
        const parts = c.split("_");
        const op = project.operations?.find((o) => o?.id === parts[0]);
        const form = forms.find((f) => f.id === op?.formId);
        const attachments = form ? getAttachments(form as Form, op?.data as DataFieldInput[]) : [];
        const attIndex = parseInt(parts[1], 10);
        let attName = "";
        if (!Number.isNaN(attIndex)) attName = attachments[attIndex];
        else if (parts[1] === "p") attName = t("doneProof");
        else if (parts[1] === "cpeProof") attName = t("cpeProof");
        else return;
        const opName = getOpName(op);
        const line = result.find((r) => r.primary === opName);
        if (line) line.secondary += ` + ${attName}`;
        else result.push({ primary: opName, secondary: attName });
      }
    });
    return result.length > 0 ? (
      <>
        {result.map((r) => (
          <div key={r.primary}>
            {r.primary}
            {r.secondary ? ` - ${r.secondary}` : ""}
          </div>
        ))}
      </>
    ) : (
      ""
    );
  };
  return (
    <>
      <WarningCard
        className="margin-bottom"
        bodyData={{
          content: (
            <span>
              {t("attachmentsWarning.message1Start")}
              <Link to="/faq">{t("attachmentsWarning.message1Link1Label")}</Link>
              {t("attachmentsWarning.message1End")}
              <a
                href="https://greendeed.blob.core.windows.net/article-media/Tutoriel_facture_CEE.pdf"
                target="_blank"
                rel="noreferrer">
                {t("attachmentsWarning.message1Link2Label")}
              </a>
            </span>
          ),
        }}
        warning
      />
      <WarningCard
        className="margin-bottom"
        bodyData={{
          titleLabelKey: "attachmentsWarning.message2Title",
          content: (
            <div style={{ textAlign: "center" }}>
              {t("attachmentsWarning.message2BodyLine1")}
              <br />
              {t("attachmentsWarning.message2BodyLine2")}
              <br />
              {t("attachmentsWarning.message2BodyLine3")}
            </div>
          ),
          noIcon: true,
        }}
        warning
      />
      <div className="row space-between align-start">
        <div>
          <Typography variant="h6">
            {t(attNeeded.length > 0 ? "attachmentsTitle" : allHaveBeenValidated ? "allAttachmentsValidated" : "")}
          </Typography>
          <List>
            {attNeeded.map((an) => (
              <ListItem key={an?.primary}>
                <ListItemText primary={an?.primary} secondary={an?.secondary} />
              </ListItem>
            ))}
          </List>
        </div>
        {project.isReadOnly ? undefined : (
          <Tooltip title={t(allHaveBeenValidated ? "addAdditionalAttachment" : "addAttachment") as string}>
            <IconButton onClick={() => setOpen(true)} color="primary" size="large">
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {project.attachments && project.attachments.length > 0 ? (
        <>
          <Typography variant="h6">{t("sentAttachmentsTitle")}</Typography>
          {project.attachments.map((a) => (
            <ListItem
              key={a.id}
              secondaryAction={
                <Tooltip title={t("seeAttachment") as string}>
                  <IconButton onClick={() => window.open(a.url)} color="secondary">
                    <Visibility />
                  </IconButton>
                </Tooltip>
              }>
              <ListItemText
                primary={
                  <Typography>
                    {a.name} -{" "}
                    <span
                      style={{
                        color: a.validation
                          ? a.validation.status === ValidationStatus.Accepted
                            ? Theme.palette.success.main
                            : Theme.palette.error.main
                          : undefined,
                      }}>
                      {a.validation
                        ? t(
                            `sentAttachmentsStatus.${
                              a.validation.status === ValidationStatus.Accepted ? "accepted" : "refused"
                            }`,
                            { replace: { reason: a.validation.comment } },
                          )
                        : t("sentAttachmentsStatus.ongoing")}
                    </span>
                  </Typography>
                }
                secondary={getConcerns(a)}
              />
            </ListItem>
          ))}
        </>
      ) : (
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {t("noAttachmentYet")}
        </Typography>
      )}
      <AddAttachmentModal project={project} open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Attachments;
